.route-history {
    height: 100%;

    .treeViewTableDataIcon {
        margin: 8px 0 8px 0;
    }
}

#routeHistoryTreeViewTable {
    height: calc(100% - 54px);

    .driver-cell { min-width: 75px; }

    .date-cell { min-width: 120px; }

    .shift-cell { min-width: 70px; }

    .route-variant-cell { min-width: 85px; }

  	.trip-state-cell { width: 84px; }

    .vehicle-cell { min-width: 70px; }
}

.route-header {
    width: 100%;
    line-height: 16px;
    font-size: 0.8em;
    color: #333;
}

.route-variant-header {
    float: left;
}

.route-sub-header {
    font-size: 1.2em;
    color: #000;
}

.shift-header {
    float: left;
}

.route-history-filter-container {
    .input-group {
        float: right;
        width: 300px;
    }
}
