.centered-on-time-running-routes {
    display: block;
    text-align: center;
}

.onTimeRunningGroupHeader {
    font-size: 20px;
}

.onTimeRunningTable {
    table-layout: fixed;
    padding: 20px;
    font-size: 18px;
}

.onTimeRunningTable .row-otr-route {
    width: 82px;
}

.onTimeRunningTable .row-otr-percent {
    width: 82px;
}

.onTimeRunningTable .row-otr-total {
    width: 82px;
}

.onTimeRunningTableHeaders {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: white;
    border: none;
}

.onTimeRoutesTable .onTimeRunningTableHeaders {
    background: linear-gradient(to bottom left, rgba(51, 153, 51, 0.9), rgba(51, 153, 51, 0.5));
}

.lateRoutesTable .onTimeRunningTableHeaders {
    background: linear-gradient(to bottom left, rgba(0, 112, 192, 0.9), rgba(0, 112, 192, 0.5));
}

.earlyRoutesTable .onTimeRunningTableHeaders {
    background: linear-gradient(to bottom left, rgba(192, 0, 0, 0.9), rgba(192, 0, 0, 0.5));
}

.onTimeRunningTableHeaders td{
    border: none;
}

.onTimeRunningTable tr td{
    padding: 10px;
}

.onTimeRoutesTable tr td {
    border: 1px solid rgba(51, 153, 51, 1);
    background-color: rgba(51, 153, 51, 0.05);
}


.lateRoutesTable tr td {
    border: 1px solid rgba(0, 112, 192, 1);
    background-color: rgba(0, 112, 192, 0.05);
}

.earlyRoutesTable tr td {
    border: 1px solid rgba(192, 0, 0, 1);
    background-color: rgba(192, 0, 0, 0.05);
}

.onTimeStatsVehiclesPopover {
    max-width: 600px;
    width: 500px;
    font-size: 14px;
}

.onTimeStatsVehiclesTable {
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
}

.onTimeStatsVehiclesTable tr td {
    text-align: left;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    padding: 0px;
}

.ellipsisTableCell {
    text-overflow: ellipsis;
}

.onTimeStatsVehiclesTable th {
    /*dont wrap text?*/
    font-size: 10px;
}

.onTimeStatsVehiclesTable .row-busId {
    width: 10%;
}

.onTimeStatsVehiclesTable .row-deviation {
    width: 15%;
}

.onTimeStatsVehiclesTable .row-routevar {
    width: 15%;
}

.onTimeStatsVehiclesTable .row-trip {
    width: 60%;
}

#realTimeRunningStatsContainer {
    padding: 10px;
    margin: 0px;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    overflow-x: hidden;
    overflow-y: hidden;
    display: block !important;
    white-space: nowrap !important;
    float: none !important;
}

ptips-dashboard {
    display: block !important;
    width: 100%;
    height: 100%;
    float: none !important;
}

#dashboardContentsContainer {
    display: block !important;
    width: 100%;
    height: calc(100% - 30px);
    float: none !important;
    overflow-x: hidden;
}

#dashboardContentsContainer > [ng-transclude] {
    height: 100%;
}

.singlePage #prevControl,
.singlePage #nextControl {
    display: none;
}

#prevControl, #nextControl {
    opacity: 0;
    position: absolute;
    top: 50%;
    z-index: 100;

    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

#nextControl {
    right: 20px;
}

#prevControl {
    left: 20px;
}

#dashboardContentsContainer:hover #prevControl,
#dashboardContentsContainer:hover #nextControl {
    opacity: 0.5;
}

#dashboardContentsContainer #prevControl:hover,
#dashboardContentsContainer #nextControl:hover {
    opacity: 1;
}

.dashboardPage {
    display: inline-block !important;
    float: none !important;
    white-space: nowrap !important;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal !important;
    overflow-x: hidden;
}

#realTimeRunningStatsRegionContainer {
    overflow-x: hidden;
    overflow-y: auto;
}

#depotChartsContainer {
    white-space: nowrap !important;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-x: auto;
}

.depotChartLabelText {
    font-family: @font-family-base;
}

.depotPieChartContainer {
    width: 200px;
    height: 220px;
    float: left;
    padding-right: 10px;
}

.forwardBackControl {
    cursor: pointer;
}

#realTimeRunningStatsMainChartsContainer,
#realTimeRunningStatsRegionContainer {
    .legendLabel {
        padding-left: 2px;
        padding-right: 10px;
    }
}