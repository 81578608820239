.route-summary-plot-container {
  width: 100%;
}

.route-summary-vehicle-count-box {
	width: 100%;
}

.route-summary-plot-box {
  width: 100%;
  height: 22px;
  border: 1px solid lightgrey;
  padding: 2px;
}

.route-summary-line {
  margin: 0 auto;
  width: 100%;
  border-left: 1px solid dimgrey;
  border-right: 1px solid dimgrey;
  height: 16px;
  padding-top: 8px;
}

.route-summary-line hr {
  margin: 0 auto;
  width: 100%;
  border-color: dimgrey;
}

.route-summary-plot {
  position: relative;
  width: calc(100% - 16px);
  height: 18px;
  left: 1px;
  top: -15px;
}

.route-summary-plot-vehicle {
  position: absolute;
  top: 0px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: @onTimeVehicleColour;
  border: 1px solid black;
}

.between-trips {
  background: @deadRunningVehicleColour;
}

.late {
  background: @lateVehicleColour;
}

.early {
  background: @earlyVehicleColour;
}

.on-time {
  background: @onTimeVehicleColour;
}

.inactive {
  background: @inactiveVehicleColour;
}

.route-summary-plot-wait {
  margin: 20px auto 0;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  background-image: url('@{img-path}/animated-loading-16x16.gif');
}

.route-summary-plot-wait img {
  display: inline;
}

.route-summary-min-max-container {
  margin-top: 10px;
  width: 300px;
}
.route-summary-min-max-container div div dl dt {
    font-size: xx-small;
}

