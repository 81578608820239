pt-version-badge {
	> span {
		cursor: pointer;
	}
	.popover {
		margin-top: 5px;
	}
	.option-panel {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

#header {
	padding-right: 0;
}

#navigation {
	border-width: 0;
}

#navigation .navbar-brand {
	padding: 0;
	margin-top: 8px;
	margin-right: 20px;

	.btn.btn-toolbar {
		.button-size(3px; 8px; 20px; @line-height-base; @border-radius-base)
	}
}

#navigation .navbar-right {
  padding: 0;
}

.tfnsw-logo {
	content: url('@{img-path}/tfornsw-nswgovt_grey.png');
	height: 38px;
	width: auto;
	padding: 0;
}

.username, .version {
	margin-top: 8px;
	margin-bottom: 0;
}
