.ptcharts-container {
  .axis {
	cursor: pointer;
	text {
	  transition: all 1s;
	  font-weight:bold;
	  text-anchor: end;
	  font-size:12px;
	  -webkit-touch-callout: none;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	  &:hover {
		fill:maroon;
		transition: all 1s;
	  }
	}

	path {
	  fill: none;
	  stroke: #444;
	  stroke-width: 3px;
	  stroke-opacity: 1;
	  text-anchor: middle;
	  font-size: 10px;
	  stroke-linecap: round;

	}

	line {
	  fill: none;
	  stroke: #444;
	  stroke-width: 2px;
	  stroke-opacity: 1;
	  text-anchor: middle;
	  font-size: 10px;
	  stroke-linecap: round;
	  stroke-linejoin: round;
	}
  }
}
