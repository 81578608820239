.ptcharts-container {
  .toggle {
	cursor: pointer;

	rect {
	  fill-opacity: 1;

	  font-weight: bold;
	}

	text {
	  font-size:10px;
	  font-weight: bold;
	  -webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none;   /* Chrome/Safari/Opera */
	  -khtml-user-select: none;    /* Konqueror */
	  -moz-user-select: none;      /* Firefox */
	  -ms-user-select: none;       /* IE/Edge */
	  user-select: none;
	}

	path {
	  fill:default !important;
	}
  }
}
