@import (reference) "../../vendor/bootstrap/less/bootstrap.less";
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "variables.less";
@import "helpers/colors";
@import "helpers/shapes";
@import "helpers/tooltips";
@import "helpers/gradient";
@import "core";
@import "vendor/bootstrapOverrides";
@import "vendor/kendoUiOverrides";
@import "vendor/koalitePushButtons";
@import "layout";
@import "header";
@import "tables";
@import "forms";
@import "ptipsUi";
@import "alerts";
@import "deviation";
@import "animations";
@import "contextMenu";
@import "busTracking";
@import "loginForm";
@import "search";
@import "vehicleSearch";
@import "timeDistance";
@import "vehicleHistory";
@import "vehicleDetails";
@import "colourPicker";
@import "map";
@import "charts/charts";
@import "onTimeRunning";
@import "statusBar";
@import "statusMonitor";
@import "multiSelect";
@import "globalFilter";
@import "treeViewTable";
@import "shiftReport";
@import "vehicleSummary";
@import "transitStopHistory";
@import "mta";
@import "routeHistory";
@import "easterEgg";
@import "directives/horizontalScroll";
@import "directives/userMenu";
@import "orbs";
@import "onOffSlider";
@import "vehicleIcons";
@import "slider";
@import "icons";
@import "bootstrap-kendo-nesting";
@import "navbar-container-responsive";
@import "routeSummaryPlot";
@import "gmaps-print-override";
@import "userPreferences";
@import "tripSummary";
@import "tripCancellationReport";
@import "headway";


body {
  font-family: @font-family-open-sans;
  overflow: hidden;
}

/*
 * Ng-grid
 */
.ngHeaderText {
  font-weight: 100;
}

/**
   Icon Btn & Icons
*/
.btn-toolbar {
  font-size: 18px;
}

.btn-toolbar.caret {
  min-height: 25px;
  padding-right: 3px;
}

.btn-toolbar:active,
.btn-toolbar.active {
  background-color: #363636;
  color: white;
  border-color: #363636;
}

.btn-toolbar, .btn-dropdown {
  .caret {
	position: relative;
	vertical-align: text-top;
  }
}

.ptipsicon-small {
  font-size: 12px;
}

/**
  UIX Framework
*/

#container {
  height: 100%;
  border-width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#verticalSplitter {
  height: 100%;
  border-width: 0;
}

#horizontalSplitter {
  height: 100%;
  border-width: 0;
}

#bottomPanel {
  height: 100%;
  border-width: 0;
}

.listBox {
  border: lightgray solid 1px;
  overflow: auto;
  padding: 10px;
}

.listBox > ul {
  -webkit-padding-start: 0px;
}

.listBox > li {
  list-style-type: none;
  -webkit-padding-start: 0px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid white;
}

.listBox > li:hover {
  background-color: lightgrey;
  border: 1px solid darkgrey;
}

.listBox > li > input,
.listBox > li > span {
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

/*
 *  Padded
 */
.pad-right {
  padding-right: 16px;
}

.pad-left {
  padding-left: 16px;
}

.pad-bottom {
  padding-bottom: 16px;
}

.pad-top {
  padding-top: 16px;
}

.no-padding {
  padding: 0px;
}

.pad-left-small {
  padding-left: 10px;
}

.btn-brdr-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * Option Panels
 */
.option-panel {
  padding: 0 10px;
}

.option-panel div {
  padding-top: 6px;
  white-space: nowrap;
}

.option-panel .ptipsicon {
  padding-right: 8px;
}

.option-panel div.no-icon-small {
  padding-left: 20px; /* ptipsicon-small width (12px) plus the padding-right (8px) */
}

.option-panel div.suboption {
  padding-top: 2px;
}

/*
 * Info Windows (intended for re-use by multiple views such as charts and maps).
 */

div.info-window {
  display: none;
}

.info-window-wait {
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  background-image: url('@{img-path}/animated-loading-16x16.gif');
}

.info-window-wait-center {
  position: fixed;
  top: 50%;
  left: 50%;
  font-size:18px;
}

.info-window-wait img {
  display: inline;
}

.info-window-block-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.info-window-organisationId {
  /* For both InfoWindows the organisationId is tiny, unlabelled and at the top right. */
  margin-right: 0px;
  margin-bottom: 0.5em;
  text-align: right;
  clear: both;
  font-size: 8px;
  font-weight: normal;
}

/** These can be put side-by-side in an info-window-block-container, and contain info-window-rows. */
.info-window-block {
  margin: 0 7px;
}

.info-window-row {
  display: block !important;
  white-space: nowrap !important;
  float: none !important;
}

.info-window-row dl {
  display: inline-block;
  margin: 3px 7px;
}

.info-window-row dl:first-child {
  margin-left: 0px;
}

.info-window-row dl:last-child {
  margin-right: 0px;
}

.info-window-column {
  vertical-align: top;
}

.info-window-column dl {
  display: block;
  margin-bottom: 7px;
}

.info-window-column:not('.info-window-to-be-continued') dl:last-child {
  margin-bottom: 0px;
}

.info-window-row dt,
.info-window-column dt {
  font-weight: normal;
}

.info-window-row dt,
.info-window-column dt {
  font-size: 10px;
}

.info-window-row dd,
.info-window-column dd {
  font-weight: bold;
  font-size: 15px;
}

.info-window-row dd .dd-detail,
.info-window-column dd .dd-detail {
  font-weight: normal;
  font-size: 12px;
}

.units {
  font-size: smaller;
}

/**********************************************************
 *
 * Styles specific to PTIPS views start here.
 *
 * Each view has its own subsection, which may also be
 * divided into sections. Please stick to this convention.
 *
 **********************************************************/

/*
 * Header
 */

.bg-color-unknown {
  background-color: #525252 !important;
}

.bg-color-total-vehicle {
  background-color: #828282 !important;
}

.bg-color-active-vehicle {
  background-color: #356E35 !important;
}

.bg-color-inset-vehicle {
  background-color: #FFF !important;
  color: #000;
  font-size: xx-small !important;
}

.bg-color-inset-vehicle.disabled {
  color: #D8D8D8;
}

/**
  Background styles for Route Progress directive.
*/

.bg-colour-lightgray {
  background-color: lightgray !important;
}

.bg-colour-clear {
  background-color: rgba(0, 0, 0, 0) !important;
}

/**
	Vehicle Summary Panel
*/

.ng-grid-cell-vehicleId {
  font-weight: bold;
  overflow: visible;
}

.ng-grid-cell-routeId {
  font-weight: bold;
  overflow: visible;
}

/**
	Value changed from "fixed"; see issue PWP-2935.
*/
.ngHeaderScroller {
  position: relative;
}

.scheduledOffsetBox {
  position: relative;
  top: 3px;
  left: 0;
  height: 85%;
}

.vehicleOffsetBox {
  position: relative;
  top: -17px;
  left: 0;
  height: 40%;
}

.redRow {
  color: red;
  font-weight: 500;
}

.blueRow {
  color: blue;
  font-weight: 500;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.export-dialog-button {
  margin-top: 15px;
  float: right;
}

#dashboardContentsLoading {
  position: absolute;
  top: 445px;
  right: 50%;
  z-index: 100;
  font-size: 32px;
}

.dashboardIconButton {
  cursor: pointer;
}

.status-badge-content {
  max-height: 400px;
  overflow-y: auto;
}

#messageManagerBox {
  color: #38557A;
}

#messageManagerBoxLabel {
  display: inline-block;
  margin: 20px 20px 0 55px;
}

#messageManagerBoxButton {
  display: inline-block;
}

#messageManagerBoxButton label {
  font-size: 11px;
}

.larger-text {
  font-size: 1.2em;
}

.view-container {
  padding: 10px;
}

.scrollable {
  overflow-y: auto;
  height: 100%;
}

.btn-wide {
  width: 205px;
}

.centre {
  text-align: center;
}

.validation-highlight (@color) {
  border-color: @color;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .btn {
  .validation-highlight(#3C763D);
}

.has-error .btn {
  .validation-highlight(#A94442);
}

@lightestgrey: rgb(243, 243, 243);
@lightgrey: rgb(234, 234, 234);
@darkergrey: rgb(212, 212, 212);

@largerFontSize: 1.2em;

.grid {
  border: 1px solid @darkergrey;
  width: 100%;

  th {
	font-size: @largerFontSize;
	padding: 3px 0 3px 5px;
  }

  tr {
	border: 1px solid @darkergrey;
	background-color: @lightgrey;
  }

  td {
	border: 1px solid @darkergrey;
	padding-left: 2px;
	padding-bottom: 5px;
  }

  .even-row {
	background-color: @lightestgrey;
  }

  div {
	box-sizing: content-box;
  }

  .cell-title {
	font-size: @largerFontSize;
	font-weight: bold;
  }
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.remove-span {
  cursor: pointer;
  padding-left: 5px;
}

.whats-new-body {
  overflow-y: auto;
  max-height: 600px;
}

.vehicle-stale {
  color: #C00000;
}

.btn-search {
  position: relative;
  &:before {
	content: '';
	position: absolute;
	display: inline;
	top: -1px;
	left: 30px;
	left: 35px;
	width: 2px;
	bottom: -1px;
	opacity: 0.8;
	border-left: 2px solid white;
  }
  span {
	margin-left: 20px;
  }
}

