.report-form-toolbar {
    padding: 5px;
    min-height: 45px;
    border-bottom: 1px solid #ddd;

    .form-inline {
        .form-group {
            margin-top: 5px;
        }
        > button {
            margin-top: 5px;
        }
    }

    .btn-toolbar {
        margin-top: 5px;
        line-height: 1.111111; // 20/18
    }

  	.trip-cancellation-text-filter {
	  margin: 5px 0 0 0;
	  width: 300px;
	}
    .alert-warning {
        margin-top: 0 !important;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

form {
    .input-group-sm > .input-group-btn > .btn {
        box-sizing: border-box;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.loading-message {
    background-color: #eee;
    padding: 10px;
    display: inline-block;
    margin-top: 3px;

    p { margin: 0; }
}

.results-toolbar {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 15px;
    }

    .toolbar-header {
        font-size: 0.8em;
    }

    .toolbar-content {
        font-size: 1.4em;
        font-weight: bold;
    }

    li.searchFilter {
        vertical-align: bottom;
    }

    li.searchDownload {
        vertical-align: bottom;
    }

}

.single-select {
    line-height: 32px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 10px;

    &.disabled {
        color: #888;
        background-color: #eee;
    }

    &:hover {
        background-color: #e6e6e6;
        border-color: #adadad;

    }
}

form.ng-dirty {
    [pt-validate-field].ng-valid {
          .has-success.form-control;

          .btn {
             .validation-highlight(#3c763d);
           }
    }

    [pt-validate-field].ng-invalid {
        .has-error.form-control;

        .btn {
            .validation-highlight(#a94442);
          }
    }
}

form.ng-dirty {
    .ptips-validation.ng-valid {
        .validation-highlight(#3C763D);
    }

    .ptips-validation.ng-valid:disabled {
        .validation-highlight(#3C763D) !important;
        opacity: 1 !important;
    }

    .ptips-validation.ng-invalid {
        .validation-highlight(#A94442);
    }
}

.form-loading {
    min-width: 350px;
    padding: 0 10px;
    width: 50%;
}
