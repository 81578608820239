
.filter-panel-standard-filters-trip-status {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  font-size: inherit;
  font-weight: inherit;
}

.filter-panel-standard-filters-time-window {
  padding-left: 10px;
  padding-right: 10px;
}

.trip-summary-view {
  height: 100%;
  overflow: auto;
}

.time-window-filter-panel {
  border: 0;
  .time-window {
	width: 257px;
	height: 130px;
	overflow: hidden;
	font-size: 12px;
  }
}

.option-panel div {
  .time-window-filter-panel {
	padding: 0;
  }
}

.trip-summary-filter-input {
  max-width: 1300px;
  .input-group input {
	width: 400px;
  }

}

.trip-status-filter-panel {
  padding: 10px;
  width: 237px;
}

.trip-status-filter-btn {
  margin-top: 10px;
}

.trip-status-filter-label {
  margin-top: 5px;
}

.trip-summary-lozenge-container {
  display: inline-block;
  vertical-align: middle;
  padding-top: 4px;

  pt-lozenges {
	padding: 0px;
  }
}

//.trip-summary-lozenge-container-helper {
//}

.trip-summary-lozenge {
  margin: 0 auto;
}


.trip-status-badge {
  width: 70px;
  color: #FFF !important;
  font-weight: normal;
  text-align: center;
}

.trip-status-badge.ACTIVE {
  background-color: #90D246 !important;
}

.trip-status-badge.OVERDUE {
  background-color: #5089D6 !important;
}

.trip-status-badge.SCHEDULED {
  background-color: #CE9B00 !important;
}

.trip-status-badge.CANCELLED {
  background-color: #7E62A0 !important;
}

.trip-status-badge.P_CANCELLED {
  background-color: #53169E !important;
}

.trip-status-badge.INCOMPLETE {
  background-color: #61261E !important;
}

.trip-status-badge.COMPLETED {
  background-color: #7F7F7F !important;
}

.trip-status-badge.UNSEEN {
  background-color: #1D457F !important;
}

.assign-trip-filter-panel-container {
  padding: 10px;
  min-width: 200px;
}

.depot-selection {
  width: 100%;
  text-align: right;

  button {
	width: 180px;
  }
}

.std-selection {
  margin-top: 10px;
}

.filter-badge.smart-filter-badge {
  background-color: #77659F;
}

.progress-container {
  position: relative;
  width: 100%;
  margin-bottom: 70px;
  top: 10px;

  .progress-bar-line {
	position: absolute;
	left: 10px;
	top: 8px;
	border-top: 4px solid #AB98C0;
	width: calc(100% - 20px);
  }

  .progress-bar-line.progress-bar-line-done {
	border-top: 4px solid #712AA1;
  }

  .progress-left {
	left: 0;
  }

  .progress-right {
	right: 0;
  }

  .progress-point {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	z-index: 10;
  }

  .progress-current {
	background: #AB98C0;
	border: 5px solid #712AA1;
  }

  .progress-todo {
	background: #FFF;
	border: 5px solid #AB98C0;
  }

  .progress-done {
	background: #712AA1;
	border: 5px solid #712AA1;
  }

  .progress-labels {
	position: absolute;
	top: 30px;
	left: 0;
	width: calc(100% - 5px);
  }

}

.trip-cancel-reason-selection-container {
  margin: 0px 5px 0 0;
  textarea {
	height: 100px;
  }

  .validation-info {
	color: darkgrey;
  }

  .validation-error {
	color: rgb(146, 57, 49)
  }
}

.vehicle-selection-container {
  margin: 0px 5px 0 0;

  > section {
	display: flex;
	flex-direction: row;
  }

  header {
	min-width: 80px;
  }

  > div {
	min-width: 70px;
  }

  pt-lozenges {
	padding-top: 27px;
  }

  .filter-btn {
	padding-top: 13px;
  }

}

.confirm-assign-trip-container {
  margin: 70px 5px 0 0;
}

.confirm-unassign-trip-container {
  margin: 10px 5px 0 0;
}

.text-search-vehicle-list {
  width: 100%;
}

.assign-vehicle-list {
  height: 300px;
  width: 100%;

  .vehicle-list {
	border-radius: 5px;
	height: 300px;
	overflow-y: auto;

	> div {
	  height: 250px;
	  position: relative;

	  > i {
		position: absolute;
		top: calc(50% - 25px);
		transform: translateX(-50%);
		left: calc(50% - 25px);
		font-size: 50px;
	  }
	}

	li {

	  .icon-container {
		vertical-align: top;
	  }

	  .content-container {
		line-height: 24px;
		padding-left: 5px;
	  }

	  .content-container, .icon-container {
		display: inline-block;
	  }
	}

	li:nth-child(odd) {
	  background-color: @lighter-grey;
	}

	li.selected {
	  background-color: @system-blue;
	  color: white;
	  a {
		color: white;
	  }
	}
  }
}

.assigned-vehicle {
  padding: 10px;
  border: 1px solid #DDD;
  border-radius: 5px;
  margin-top: 20px;

  .vehicle-icon {
	margin: 0;
  }
}

.unselected {
  color: #999;
}

.action-panel span {
  padding-left: 10px;
}

.trip-cancel-reason {
  word-break: break-all;
}

.trip-summary-form .multi-select .dropdown-menu {
  max-height: 210px;
  font-size: inherit;
}

.trip-summary-form .multi-select .dropdown-menu label {
  font-weight: inherit;
}

#trip-summary-help-button {
  padding: 6px 9px;
  height: 16px;
  span {
	position: relative;
	bottom: 3px;
  }
}

.trip-cancel-tsn-detail select {
  width: 70%;
}

.trip-cancel-type {
  display: inline;
  font-size: 14px;
}

.trip-cancel-tsn-detail select option:disabled {
  background-color: #d4d4d4;
}

.trip-cancel-confirm-title, .trip-cancel-type-title, .trip-cancel-type-title h5 {
  display: inline;
}

.config-update-container {
 width: 40%;
}

.bulk-cancel-container{
  display:flex;
  gap: 50px;
 
}
.bulk-cancel-left-container {
 width: 30%;
}

.bulk-cancel-mid-container {
  margin-top:12%;
  width: 30%
}

.red-star {
  color: rgb(146, 57, 49);
}

.bulk-cancel-right-container {
  width: 30%;
}

.bulk-cancel-input {
  width: 100%;
}

.bulk-cancel-input-reason {
  margin-left:5px;
}

.bulk-cancel-input-reason-text {
  width: 60%;
  text-align: right;
}

.single-select-item{
  a{
    cursor: default;
    color: black;
    display: block ;
    padding-left: 2.5px;
    padding-top: 2.5px;

  }
  a:hover {
     
    background-color: cornflowerblue;
    text-decoration: none;
    color:whitesmoke

  }
  a:link {

    text-decoration: none;
  }


}

.confirm-bulk-ui{

  padding: 10px;
  padding-left: 20px;
  
}


.reinstate-preheader{
  color: rgb(146, 57, 49);
  text-align: center;
}