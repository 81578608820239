.ptcharts-container {
  .gridline {
	background-color: rgba(243, 237, 183, 0.78);

	path, line {
	  fill: none;
	  stroke: #CCC;
	  opacity: 0.4;
	  stroke-width:2px;
	  shape-rendering: optimizeSpeed;
	  transition: all 0.5s;
	}
  }
}
