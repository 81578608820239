
/**
	WebSocket error messages.
  */
.ptips-alert-top {
  position: fixed;
  left: calc(50% - 150px);
  margin-top: 8px;
  margin-bottom: 8px;
  z-index: 9998 !important;
}

.ptips-alert {
  width: 300px;
  border-width: 0px;
  min-height: 45px;
  border-radius: 6px;
}

.ptips-alert .alert-btn {
  background-color: #F2F2F2;
  margin: 0px 8px 5px 5px;
  padding: 0px 5px 0px 5px;
  font-size: 10px;
  font-weight: bold;
  float: right;
}

.error:hover:not(.ptips-alert-sub), .warning:hover:not(.ptips-alert-sub), .restore:hover:not(.ptips-alert-sub) {
  cursor: pointer; cursor: hand;
}

.ptips-alert .ptipsicon {
  float: left;
  padding: 3px;
  font-size: 16px;
}

.ptips-alert .ptipsicon-times-circle {
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: white;
}

.ptips-alert .major {
  padding-top: 3px;
  font-size: 12px;
  font-weight: bold
}

.ptips-alert .minor {
  padding-left: 22px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 11px;
  white-space: normal;
}

.ptips-alert.ng-enter {
  -webkit-transition:1.0s linear all;
  transition:1.0s linear all;
  opacity:0;
}

.ptips-alert.ng-enter.ng-enter-active {
  opacity:1;
}

.ptips-alert-popover {
  color: black;
  font-size: 11px;
  max-width: 600px;
  width: auto;
}

.ptips-alert-popover .badge {
  font-size: 10px;
}

.ptips-alert-popover table {
  margin-bottom: 0px;
}

.ptips-alert-popover table td {
  white-space: nowrap;
}

.ptips-alert-sub {
  width: 34px;
  border-width: 0px;
  border-radius: 6px;
  display: inline;
  margin: 3px 3px 3px 0px;
  text-align: center;
  vertical-align:middle;
}

.ptips-alert-sub .ptipsicon {
  padding: 3px;
  font-size: 18px;
}

.ptips-alert-sub.ng-enter {
  -webkit-transition:1.0s linear all;
  transition:1.0s linear all;
  opacity:0;
}

.ptips-alert-sub.ng-enter.ng-enter-active {
  opacity:1;
}

.error:hover:not(.ptips-alert), .warning:hover:not(.ptips-alert), .restore:hover:not(.ptips-alert) {
  cursor: pointer; cursor: hand;
  background-color: lightgray !important;
}

.error {
  color: white;
  background-color: #c05046 !important;
}

.warning {
  color: white;
  background-color: #ffbf00 !important;
}

.restore {
  color: white;
  background-color: #9bbb61 !important;
}

.ptips-session-expired-modal {
  font-size: 14px;
  z-index: 9999 !important;
}