.user-preferences-body {
  &.modal-body {
	padding: 0 20px;
  }

  .nav-pills {
	.col-xs-4;
	.pull-left;
	border-bottom: none;

	& > li {
	  & > a {
		color: @text-color;
	  }

	  & > a:hover {
		border: none;
	  }
	}

	& > li.active {
	  & > a,
	  & > a:hover,
	  & > a:focus {
		border: none;
		color: @component-active-color;
		background-color: @component-active-bg;
	  }
	}
  }

  .tab-content {
	.col-xs-8;
	.pull-left;
	min-height: 350px;
	padding: 8px;
	border-left: 1px solid @nav-tabs-border-color;

	hr {
	  margin-top: 0;
	}
  }
}

.user-preferences-tab-content {
  padding: 10px 5px;

  .ptipsicon {
	position: relative;
	top: 2px;
	font-size: 14px;
  }

}

#vehicle-display-demo-icon {
  margin-top: 15px;
}