.vehicle-icon {
  margin: 5px;
  height: 16px;
  width: 16px;
  background-image: url('@{img-path}/bus-icons-states-sprite.png');
  display: inline-flex;
  vertical-align: sub;
}

.vehicle-icon-INACTIVE {
  background-position: 0px 0px;
}

.vehicle-icon-IGNITION_OFF {
  background-position: -16px 0px;
}

.vehicle-icon-DISCONNECTED {
  background-position: 16px 0px;
}

.vehicle-icon-ON_TIME {
  background-position: 0px;
}

.vehicle-icon-EARLY {
  background-position: 16px;
}

.vehicle-icon-LATE {
  background-position: -16px;
}

.vehicle-icon-NOT_ON_TRIP {
  background-position: 0px 16px;
}

.vehicle-icon-BETWEEN_TRIPS {
  background-position: -16px 16px;
}

.vehicle-icon-OFF_ROUTE {
  background-position: 16px 16px;
}


.vehicle-icon-special {
  margin: 5px;
  height: 16px;
  width: 16px;
  background-image: url('@{img-path}/special_bus-icons-states-sprite.png');
  display: inline-flex;
  vertical-align: sub;
}

.vehicle-icon-special-INACTIVE {
  background-position: 0px 0px;
}

.vehicle-icon-special-IGNITION_OFF {
  background-position: -16px 0px;
}

.vehicle-icon-special-DISCONNECTED {
  background-position: 16px 0px;
}

.vehicle-icon-special-ON_TIME {
  background-position: 0px;
}

.vehicle-icon-special-EARLY {
  background-position: 16px;
}

.vehicle-icon-special-LATE {
  background-position: -16px;
}

.vehicle-icon-special-NOT_ON_TRIP {
  background-position: 0px 16px;
}

.vehicle-icon-special-BETWEEN_TRIPS {
  background-position: -16px 16px;
}

.vehicle-icon-special-OFF_ROUTE {
  background-position: 16px 16px;
}

