@tableHeaderHeight: 34px;

.summary-table-header-background {
    background-color: @the-dark-grey;
    height: @tableHeaderHeight;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top: 1px solid @darkest-grey;
    border-bottom: 1px solid @darkest-grey;
    z-index: 1;
}

.th-inner {
    position: absolute;
    top: 1px;
    line-height: calc(@tableHeaderHeight - 1px);
    text-align: left;
    margin-left: -10px;
    padding-left: 10px;
    background-color: @the-dark-grey;
    z-index: 2;
    color:white;

    span.glyphicon {
        vertical-align: middle;
        margin-right: 5px;
        top:0px;
    }
    span.ptipsicon {
        vertical-align: middle;
        margin-right: 5px;
    }

    span.th-title {
        vertical-align: middle;
    }
}

/* Temporary solution to overlapping header problem. It is really difficult
to get a fixed table header with scrollable table contents, so we found a solution
that involves making the table cell the original value (position: static). But
position: relative is required to get the popover solution to work, so use this
instead as a compromise. */
.th-inner:hover {
    z-index: 3;
    padding-right: 5px;
    //background-color: @dark-grey;
}

.summary-table-inner-container {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
}

.summary-table-container {
    height: calc(100% - 94px);
    position: relative;
    padding-top: @tableHeaderHeight;
    min-width: 1200px;

    overflow-y:hidden;
    overflow-x: auto;
}

table.summary-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;

    tr:hover {
        td {
            background-color: #ecf3f8;
        }
    }

    th {
        line-height: 1.5;
        background-color: @dark-grey;
        border-bottom: solid @darkest-grey 2px;
    }

    th.sortable {
        cursor: pointer;
    }

    th, td {
        padding-left: 10px;
        font-weight: normal;
        .truncate-text;
    }
    td.route-progress {
        padding-left: 0px;
    }

    td {
        background-color: @lighter-grey;
        position: relative;
        border: 1px solid lightgray;
        height: 30px;
    }

    .odd td {
        background-color: @lightest-grey;
    }

    th:hover, td:hover {
        overflow: visible;
    }

    /* When table-layout is fixed, CSS does magic to prioritise widths in these proportions */

    /* Fixed widths */
    .col-vehicle-icon { width: 28px; padding:  0 5px; }
    .col-status { width: 115px; }
    .col-actions { width: 145px; }

    /* Variable min widths*/
    .col-description { min-width: 200px; }
    .col-progress { min-width: 120px; }

    /* Variable max widths */
    .col-bus { width: 100px}
    .col-route { width: 100px; }
    .col-driver { width: 90px; }
    .col-driver-id { width: 100px; }
    .col-shift { width: 115px; }
    .col-next-stop { width: 70px; }
    .col-next-stop-time { width: 80px; }
    .col-next-intersection { width: 50px; }
    .col-deviation { width: 100px; }
    .col-organisation { width: 140px; }
    .col-last-updated { width: 120px; }
    .col-scheduled-start { width: 130px; }
    .col-depot { width: 140px; }
    .col-assignment { width: 110px; }
    .col-trip-start { width: 130px; }
    .col-trip-end { width: 130px; }
    .col-first-tsn { width: 135px; }
    .col-last-tsn { width: 130px; }
    .col-next-intersection { width: 110px; }
    .col-next-stop-time { width: 140px; }
    .col-next-stop { width: 100px; }

    .deviation {
        padding-left: 2px;
    }
}

.caret.caret-reversed {
    border-top-width: 0;
    border-bottom: 4px solid white;
}

