.pt-scroll-container {
  display: flex;
  overflow-x: hidden;
}

.pt-scroll-content {
  width: calc(100% - 40px);
  float: left;
  overflow: hidden;
  flex: 1;
}

.pt-scroll-btn {
  padding: 5px;
  border-radius: 0;
  height: 23px;
  font-size: 8px;
}
