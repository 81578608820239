.hw-loading {
  position: absolute;
  width: auto;
  height: auto;
  top: 40%;
  right: 20%;
  left:calc(20% + 40px);
  background-color: white;
  text-align: left;
  font-size:medium;
  padding:10px;
  border: 1px solid grey;

  .glyphicon {
    font-size: xx-large !important;
    vertical-align: middle !important;
  }
}

.center-no-data-inbound {
  position: absolute;
  width: auto;
  height: auto;
  top: 20%;
  right: 20%;
  left:calc(20% + 40px);
  background-color: white;
  text-align: center;
  font-size:medium;
  padding:10px;

  border: 1px solid grey;
}

.center-no-data-outbound {
  position: absolute;
  width: auto;
  height: auto;
  top: 67%;
  right: 20%;
  left:calc(20% + 40px);
  background-color: white;
  text-align: center;
  font-size:medium;
  padding:10px;

  border: 1px solid grey;
}
.scrollable-route-variant-selection {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}
