.map-toolbar-background {
    background-color: rgba(255,255,255,0.7);
}

/* the map itself */

.live-map {
    width: 100%;
    height: 100%;
    min-height: 300px;
}

.map-toolbar {
    margin: 5px;
    font-family: @font-family-base;
}

.toolbar-btn-container {
    display: inline-block;
}

.toolbar-btn-container .btn-dropdown {
    padding-right: 6px;
}

.map-live-traffic-legend-container {
    display: flex;
    flex-direction: row-reverse;
}

.map-live-traffic-legend {
    color: #8c8c8c;
    border: 1px solid #ececec;
    padding: 6px;
    margin-top: 3px;
    background-color: white;
}

.map-live-traffic-legend-image {
    background-image: url('@{img-path}/traffic-legend.png');
    width: 78px;
    height: 7px;
    display: inline-block;
    margin: 0 5px;
}

/* map popovers */

/* this makes the CSS behave a little more like what I, at least, would expect, and $element.width() too --ScheperM */
.map-info-box-supercontainer
.map-info-box-supercontainer * {
    box-sizing: border-box;
}

.map-info-box-content-container {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid @panel-default-border;
    border-radius: 4px;
    /* Move down 1px so the pointer covers the bottom border edge. */
    position: relative;
    top: 1px;
    /* Z-Index is relative to its parent */
    z-index: 0;
}

.map-info-box-pointer {
    box-sizing: border-box;
    height: 20px;
    background-image: url('@{img-path}/tmp/popover-pointer_32x32.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: 1;
}

.map-info-box-component-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.map-info-box-supercontainer {
    box-sizing: border-box;
    font-family: @font-family-base;
}

.map-popover {
    padding: 1em;
    box-sizing: border-box;
}

.map-popover * {
    box-sizing: border-box;
}

.map-popover .info-window-wait {
    width: 100%;
    height: 100%;
    min-width: 20em;
    min-height: 6ex;
    background-position: center center;
    background-repeat: no-repeat;
}

.map-popover dl {
    margin-bottom: 0.7em;
}
/* map mouse coordinates */

.map-mouse-coordinate-view {
    font-size: 10px;
}

.map-mouse-ordinate, .map-mouse-coordinate-mouseout {
    background-color: rgba(255, 255, 255, 0.55);
    padding: 0 6px;
    margin-left: 1px;
}

.map-mouse-ordinate {
    float: left;
}

.map-mouse-ordinate-heading {
    font-size: 8px;
}

.map-mouse-ordinate-heading, .map-mouse-ordinate-data {
    display: inline;
}


.route-click-popover-content, .route-mouseover-popover-content {
    display: flex;
    flex-direction: row;

    > div {
        padding: 0 10px;
        position: relative;

        &.context-group {
            min-width: 320px;
        }

        &:not(:first-child):before {
            .context-group-vertical-border;
        }
    }
}
