.circle (@color: "white", @radius: 6px, @border: 1px solid black) {
    @diameter: (@radius * 2);

    border-radius: @radius;
    background-color: @color;
    width: @diameter;
    height: @diameter;
    border: @border;
    display: inline-block;
    position: relative;
    top: 1px;
}

.splitCircle (@firstColor: "red", @secondColor: "white", @radius: 6px, @border: 1px solid black) {
    @diameter: (@radius * 2);

    width: @diameter;
    height: @diameter;
    position: relative;
    top: 1px;
    display: inline-block;
    div {
        position: absolute;
        height: @diameter;
        width: @radius;
        &.red {
            background-color: red;
        }
        border: @border;
    }
    div:first-child {
        border-radius: 0 @diameter @diameter 0;
        transform: rotate(45deg);
        left: (@diameter * 0.354);
        background-color: @firstColor;
    }
    div:last-child {
        border-radius: @radius 0 0 @radius;
        transform: rotate(45deg);
        top: (@diameter * -0.354);
        background-color: @secondColor;
    }
}
