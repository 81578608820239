pt-tree-view-table {
    display: block;
    width: 100%;
    overflow-y: auto;
}

.treeViewTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.treeViewTable > thead {
    background-color: #d8d8d8;
}

.treeViewTable > thead > tr > th {
    padding: 0;
}

.treeViewTable > tbody {
    background-color: #EAEFF5;
}

.treeViewTable > tbody > tr > td {
    padding: 0;
}

.treeViewTableHeader {
    border: 1px solid #888;
}

.treeViewTableHeaderData {
    margin: 0;
    padding: 0;
}

.treeViewTableHeaderData,
.treeViewTableHeaderFilter,
.treeViewTableHeaderToolbar {
    float: left;
}

.treeViewTableHeaderFilter {
    margin-top: 3px;
    margin-left: 30px;
}

.treeViewTableHeaderData > li {
    float: left;
    list-style-type: none;
    padding: 0 20px 0 0;
}

.treeViewTableHeaderToolbar {
    padding: 0;
    list-style-type: none;
    margin: 0 50px 0 0;
}

.treeViewTableHeaderToolbar > li {
    float: left;
    width: 27px;
    height: 25px;
    line-height: 30px;
    font-size: 20px;
    background-color: #f2f2f2;
    padding: 0;
    text-align: center;
    border: 1px solid #888;
    margin-right: -1px;
    margin-top: 1px;
}

.treeViewTableHeaderData .treeViewTableHeaderTitle {
    font-size: 0.8em;
}

.treeViewTableDataHeader .treeViewTableHeaderValue {
    font-size: 1.4em;
    line-height: 16px;
}

.treeViewTableDataHeader {
    padding: 5px 5px;
    margin-bottom: -1px;
    border: 1px solid #888;
    line-height: 19px;
    font-size: 1.1em;
}

.treeViewTableDataHeader.green {
    color: #393;
}

.treeViewTableDataIcon {
    width: 20px;
    float: left;
}
.treeViewTableDataContent {
    float: left;
    width: calc(100% - 20px);
}

.treeViewTableDataIcon,
.thirdLevelDataIcon,
.toolbarButton {
    cursor: pointer;
}


.treeViewTableDataTableContainer {
    width: 100%;
    overflow: hidden;
    max-height: 0;
}

.treeViewTable tr.headerless .treeViewTableDataTableContainer {
    max-height: inherit;
}

.treeViewTableDataTableContainer.open {
    max-height: 20000px;
}

.treeViewTableDataTable {
    width: 100%;
}

.treeViewTableDataTable td,
.treeViewTableDataTable th {
    border: 1px solid #888;
    padding: 5px;
}
.treeViewTableDataTable th {
    background-color: #f2f2f2;
    font-size: 0.9em;
}
.treeViewTableDataTable td {
    font-size: 0.9em;
}
.treeViewTableDataIcon {
    font-size: 16px;
}

.thirdLevelDataIcon {
    width: 16px;
    float: left;
    height: 14px;
}

.thirdLevelDataContent {
    float: left;
}

.thirdLevelDataIcon.invisible {
    visibility: hidden;
}

.treeViewTableDataTableContainer tbody.even {
    background-color: white;
}

.treeViewTableDataTableContainer tbody.odd {
    /* leave as original bg color */
}

.noRecordsMessage {
    padding: 10px;
    display: block;
    width:auto;
    margin:20px auto;
    transition: opacity 0.5s ease;

    h3 {
        margin-top: 0px;
        margin-bottom: 0px;

        i {
            margin-top:5px;
            font-size:20px;
            vertical-align: middle;
        }

        span {
            padding-left: 10px;
            font-size: 14px;
            line-height:26px;
            vertical-align: middle;
        }
    }

    p {
        text-indent: 36px;
    }

    &:after {
        content:'';
        display:block;
        clear:both;
    }
}

.loadingMessage {
    padding: 10px;
    display: block;
    margin:20px auto;
    transition: opacity 0.5s ease;

    h3 {
        margin-top: 0px;
        margin-bottom:0px;

        i {
            vertical-align: middle;
            height:100%;
            font-size:18px;
        }

        span {
            &:first-of-type {
                padding-left:10px;
            }
            font-size:14px;
            vertical-align: middle;
            display:inline-block;
        }
    }

    p {
        text-indent: 30px;
    }

    &:after {
        content:'';
        display:block;
        clear:both;
    }
}

.loadingErrorMessage {
    background-color: #bb3333;
    padding: 10px;
    display: inline-block;
    margin-top: 3px;
    color: white;
}

.noRecordsMessage p, .loadingMessage p, .loadingErrorMessage p {
    margin: 0;
}

.headerProperties {
    font-size: 0.8em;
}

.highlight-text {
    font-weight: bold;
    background-color: yellow;
}

.results-toolbar {
    line-height: 18px;
}

.treeViewTableDataTableContainer {
    .searchActivated.containsRowMatch &,
    .searchActivated.containsSubrowMatch & {
        max-height: 10000px;
    }
}

.subRow.containsSubrowMatch {
    .searchActivated & {
        display: table-row;
    }
}

.tree-view-table-context-menu {
    width: 100%;
}
