
#statusMonitorContainer {
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 10px;
}

#dashboardContentsContainer {
  overflow-y: hidden;
}

ptips-status-monitor-table {
  display: block;
  padding-bottom: 20px;
}

ptips-status-monitor-table td {
  height: 36px;
}

ptips-status-messages {
  margin: 0 auto;
}

ptips-status-light {
  display: block;
  float: left;
  position: relative;
}

.column-width-one {
  width: 38px;
  max-width: 38px;
}

.column-width-two {
  width: 76px;
  max-width: 76px;
}

.column-width-three {
  width: 114px;
  max-width: 114px;
}

#statusBarLights {
  float: right;
  margin-right: 5px;
  margin-top: -3px;
}

#statusBarLights .ball {
  height: 22px;
  width: 22px;
  margin-top: 2px;
}

.statusLightContainer {
  width: 30px;
  height: 30px;
  padding: 4px;
}

.status-monitor-table {
}

.status-monitor-table .footer {
  text-align: center;
}

.statusMonitorEntityRowCell {
  font-size: 1.4em;
}

.statusMonitorEntityRowCell.red,
.statusLightContainer.red {
  background-color: #F2DCDA;
}

.statusMonitorEntityRowCell.yellow,
.statusLightContainer.yellow {
  background-color: #FFC;
}

.statusMonitorEntityRowCell.gray,
.statusLightContainer.gray {
  background-color: #F2F2F2;
}

.statusMonitorEntityRowCell.green,
.statusLightContainer.green {
  background-color: #EBF1DF;
}
.statusMonitorEntityRowCell.blue,
.statusLightContainer.blue {
  background-color: #CEDDF1;
}

ptips-status-monitor-table table {
  padding: 5px;
}

.status-monitor-table > thead > tr,
.status-monitor-table > tbody > tr {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.status-monitor-table td:not(:last-child):not(.status-monitor-organisation) {
  border-right: 1px solid white;
}

.status-monitor-table td:not(:first-child):not(.status-monitor-sys-error-msg) {
  border-left: 1px solid white;
}

.status-monitor-table th:first-child {
  padding: 0 10px 0 10px;
  min-width: 300px;
  max-width: 700px;
}

ptips-status-monitor-table th {
  padding: 2px 2px 5px 2px;
}

ptips-status-monitor-table th:not(:first-child) {
  text-align: center;
}

.statusMessages {
  width: 300px;
  float: left;
  padding: 4px 5px 0 5px;
  height: 100%;
}

.statusMessages h3 {
  font-size: 1.3em;
  float: left;
  margin-top: 3px;
}

.statusMessages time {
  float: right;
}

.statusMessagesPageScroller,
.statusMessagesPageCount {
  float: right;
  display: inline-block;
  margin: 3px;
}

.statusMessagesPageScroller a {
  color: black;
}

.statusMessagesPageScroller a:hover {
  text-decoration: none;
}

.statusMessagesPageScroller {
  bottom: 3px;
  position: relative;
  font-size: 1.7em;
  letter-spacing: -6px;
  line-height: 12px;
}

.status-monitor-body {
    display: table;
    width: 100%;
}

.status-monitor-table {
  display: table-cell;
  float: none;
  height: 900px;
}

.pink-shader {
  background-color: #FCEBDD;
}

.blue-shader {
  background-color: #EAEFF5;
}

.pink-shader .statusLightContainer {
  padding: 2px;
  margin-left: 2px;
  margin-top: 2px;
}

.blue-shader .statusLightContainer {
  padding: 2px;
  margin-left: 2px;
  margin-top: 2px;
}

.monitor-0,
.monitor-1,
.monitor-4 {
  padding-left: 20px;
}

.angled {
  max-width: 36px;
  transform: translate(5px, 31px) rotate(-67.5deg);
  transform-origin: 0% 0%;
  white-space: nowrap;
  overflow: visible;
  text-align: center;
}

.angled span {
  padding: 8px 50px 10px 12px;
}

.angled.monitor-0 {
  transform: translate(17px, 47px) rotate(-67.5deg);
}

.angled.monitor-1 {
  transform: translate(17px, 47px) rotate(-67.5deg);
}

.angled.monitor-4 {
  transform: translate(17px, 47px) rotate(-67.5deg);
}

.row-status-container {
  height: 38px;
  width: 38px;
}

.ball .ptipsicon {
  font-size: 1.6em;
  color: white;
  line-height: 1.5;
  margin-left: 5px;
}


.status-monitor-organisation {
  width: 250px;
  padding: 0px 10px 0px 10px;
}

.status-monitor-sys-error-msg {
  font-size: 0.7em;
  text-align: right;
  font-style: italic;
  white-space: pre;
  padding: 0px 10px 0px 10px;
}

.status-monitor-table-frame {
  padding-top: 60px;
  padding-right: 30px;
  overflow: hidden;
}

