html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 12px;
}

a {
    cursor: pointer;
}

._100 {
    height: 100%;
    width: 100%;
    min-height: 100px;
    border-width: 0;
    margin: 0;
    padding: 0;
}

.flex-container {
    display: flex;
}

.flex-container-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-grow-2 {
    flex-grow: 2;
}

.flex-grow-3 {
    flex-grow: 3;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.nobr {
    white-space: nowrap;
}

.inline-block {
    display: inline-block;
}

.nowhere {
    /* A place to put elements such as map popovers, which will be moved elsewhere before they appear */
    float: left;
    position: absolute;
    top: -1000px;
    left: -1000px;
    background: red;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
