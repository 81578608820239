.ptcharts-container {
  .series-dotted {
	.series-dotted-path path {
	  fill: none;
	  stroke-width: 2px;
	  opacity: 0.7;
	  transition: all 0.2s;
	  shape-rendering: optimizeSpeed;
	}

	.series-dotted-circles circle {
	  stroke-width: 0.7px;
	  &:hover {
		cursor: pointer;
	  }
	}
  }
}