#orbsStatsContainer {
  padding: 10px;
  margin: 0px;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  overflow-x: hidden;
  overflow-y: hidden;
  display: block !important;
  white-space: nowrap !important;
  float: none !important;
}

.orbsVehicleDetailBdcTripTimeInactive {
  color: #d81c1c;
}

.orbsVehicleDetailTripDiv {
  top: -69px;
  position:relative;
}

.orbsVehicleDetailTripTitle {
  top: 50px;
  left: 0px;
  position:relative;
}

.orbsVehicleDetailTripStatusInfoNoData
{
  left:17px;
  top: 28px;
  position:relative;
}

.orbsVehicleDetailTripStatusRightButton {
  left:15px;
  top:22px;
  position:relative;
  z-index:1;
}

.orbsVehicleDetailTripStatusLeftButton {
  top:56px;
  position:relative;
  z-index:10;
  left:1845px;

}

.orbsFlatButton {
  border-style: none;
  background: none;
  outline: none;

  :focus {
    background:none;
    outline: none;
    border-style: none;
  }
}

.orbsVehicleDetailTripStatusTrackingButton {
  left:1090px;
  top:410px;
  position:absolute;
  z-index:10;
}

.outerVehicleDetailTripStatus {
  position:relative;
  top: -10px;
}

.outerVehicleDetailTripStatusNB {
  position:relative;
  top: 61px;
}

.innerVehicleDetailTripStatus {
  overflow-x:hidden;
  overflow-y:hidden;
  width:1693px;
  margin-left:111px;
}

table.orbsVehicleDetailTripStatus {
  table-layout: fixed;
  th{
    border: 1px solid #ccc;
    padding: 5px;
    height: 19px;
    text-align:center;
    vertical-align:middle;
    position:absolute;
    left:78px;
  }

  td{
    border: 1px solid #ccc;
    padding: 5px;
    text-align:center;
    vertical-align:middle;
    text-align:center;
  }
}

.centerSpinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.orbsVehicleDetailTripStatusFont100 {
  font-size: 11px !important;
  position:relative;
  z-index:20;
}

.orbsVehicleDetailTripStatusNoData {
  left:18px;
  position:absolute;
}

.orbsTrackingButton {
  height: 20px;
  width: 114px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 14px;
}

.orbsVehicleDetailTripStatusLegend {
  left:101px;
  top: 420px;
  position:absolute;
  table-layout: fixed;
  width: 50%;

  td{
    top:-19px;
    left:-25px;
    position:relative;
  }
}

.orbsVehicleDetailTripStatusManualStop {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background: url('@{img-path}/stop_sprite.png') -33px -1px !important;
  color: #ffffff;
  background-repeat: no-repeat;
}

.orbsVehicleDetailTripStatusCurrentStop {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background: url('@{img-path}/stop_sprite.png');
  border: 1px double #d81c1c !important;
  color: #d81c1c !important;
}

.orbsVehicleDetailTripStatusManualStopCurrentStop {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background: url('@{img-path}/stop_sprite.png') -66px 0px !important;
  border: 1px double #d81c1c !important;
  color: #d81c1c !important;
  background-repeat: no-repeat;
}

.orbsTripStatusStopDetailButton {
  border:0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  position:relative;
  z-index:20;
}

.orbsPopoverContent {
  max-width: 401px;
  color: black;
  padding: 5px;
}

.orbsVehicleDetailTripStatusLeftActive {
  height: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.orbsVehicleDetailTripStatusLeftInactive {
  height: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.orbsVehicleDetailTripStatusRightActive {
  height: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.orbsVehicleDetailTripStatusRightInactive {
  height: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
}
.orbsVehicleDetailTripStatusError {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background-color: #F87A6B;
}

.orbsVehicleDetailTripStatusWarning {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background-color: #F8F274;
}

.orbsVehicleDetailTripStatusOk {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background-color: #97F898;
}

.orbsVehicleDetailTripStatusManualLegend {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background-color: #7c019b;
  border-radius: 50%;
}

.orbsVehicleDetailTripStatusUnknown {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background-color: #e2e2e2;
}

.orbsVehicleDetailTripStatusNoStop {
  height: 19px;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
}

.orbsVehicleSummaryContainer {
  height: 500px;
  overflow-x : hidden;
  overflow-y:auto
}

.orbsVehicleSummaryBg {
  width : 1150px;
  //background-color: @dark-grey;
}

table.orbsVehicleSummary {
  table-layout: fixed;
  width :1100px;
  overflow-x: hidden;
  overflow-y: auto;

  tr:hover {
    td {
      background-color: #ecf3f8;
    }
  }
  th {
    //background-color: @dark-grey;
  }
  th.faultName {
    height :150px;
    width: 5px;
    border-bottom: solid white 2px;
    font-weight: bold;
    white-space: nowrap;
  }
  th.faultLevel {
    border: solid white 2px;
    text-align: center;
  }
  th > .rotate {
    width :230px;
    height: 20px;
    position: relative;
    top:-67px;
    transform: rotate(-45deg) translate(-23px, 52px);
    border-bottom: 2px solid @dark-grey;
    left: -18px;
    padding-bottom: 4px;
  }

  th.sortable {
    cursor: pointer;
  }

  th, td {
    padding-top: 2px;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 2px;
    font-weight: normal;
  }

  th.col-hdr-vehicle-filter{
    width: 44px;
    text-align: left;
    height : 40px;
    font-weight: bold;
    font-size: medium;
    border-bottom: solid white 2px;
    white-space: nowrap;
  }
  th.col-vehicle-filter{
    background-color: @dark-grey;
  }

  th.col-hdr-route-id{
    width : 13px;
    text-align: center;
  }
  th.col-hdr-fault-name{
    height :150px;
    width: 5px;
    border-bottom: solid white 2px;
    font-weight: bold;
    white-space: nowrap;
  }

  th.col-hdr-inactive {
    width : 5px;
    border-bottom: solid white 2px;
    font-weight: bold;
    white-space: nowrap;

  }

  th.col-name-route-id {
    border: solid white 2px;
    text-align: center;
    width: 13px;
    background-color: @dark-grey;
  }
  th.col-name-inactive{
    width: 60px;
  }

  td.col-value-vehicle-filter{
    width: 65px;
  }
  td.col-value-route-id{
    width : 20px;
    text-align: center;
    background-color: @lighter-grey;
  }

  td.col-width-orbs-ball{
    width: 8px;
    height: 8px;
  }

  td {
    position: relative;
    border: 2px solid white
  }
  td > div {
    font-size : 20px;
    font-weight: bold;
    color : white;
    text-align: center;
  }

  .odd td {
    background-color: @lightest-grey;

  }

  tbody {
    overflow:scroll;
    height:300px;
  }
  /* When table-layout is fixed, CSS does magic to prioritise widths in these proportions */
  .col-vehicle {
    text-align: left;
    width : 210px;
    height : 20px;
    font-weight: bold;
    font-size: small;
  }

  .deviation {
    padding-left: 2px;
  }
  .inlineDate {
    font-size: x-small;
  }

}

.orbsVehicleFieldContainer {
  table-layout: fixed;
  width: 1880px;
  line-height: calc(34px - 1px);

  label {
    background: #404040;
    color: white;
    display : block;
    font-weight: normal;
    padding-left: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
  td {
    padding-left: 10px;
    width : 250px;
    vertical-align: top;
    border: 1px solid @lightgrey;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
}

.orbsDepotFieldContainer {
  div {
	height : 57px
  }
  label {
	display : block;
	font-size: small;
	font-weight: normal;
	padding-top: 0px;
	margin-bottom: 0px;
  }
  span {
	font-size: medium;
	font-weight: bold;
  }
  td {
	padding-left: 10px;
	vertical-align: top;
  }
}

div#fleetStatusLegendContainer table{
  left:130px;
  position:relative;
  width: 34%;
  text-align: left;
}

div#fleetStatusLegendContainer > table > tbody > tr {
  td.legendColorBox > div[style] {
    border: 0px solid #858585 !important; }
  td.legendLabel {text-align: left;  }
}

#bvReadinessCanvas {
  margin-left: 20px;
  width: 200px;
  height: calc(100% - 87px);
  color: #ccc;
}

div#newBvReadinessContainer {
  .bvReadinessTitle {
    margin-bottom: 19px;
  }
}
div#bvReadinessLegendContainer table{
  left:15px;
  position:relative;
  width: 34%;
  text-align: center;
}

div#bvReadinessLegendContainer > table > tbody > tr {
  td.legendColorBox > div[style] {
    border: 0px solid #858585 !important;
  }
  td.legendLabel {text-align: left;  }
}

div#organisationLegendContainer > table > tbody > tr {
  td.legendColorBox > div[style]{
    border: 0px solid #858585 !important;
  }
  td.legendLabel {text-align: left;  }
}

div#organisationVehiclesLegendContainer > table > tbody > tr {
   td.legendColorBox > div[style]{
    border: 0px solid #858585 !important;
  }
  td.legendLabel {text-align: left;  }
}

div#organisationLegendContainer table{
  left:15px;
  position:relative;
  width: 17%;
  text-align: left;
}

div#organisationVehiclesLegendContainer table{
  left:15px;
  position:relative;
  width: 18%;
  text-align: left;
}

.orbsVehicleTripDetailContainer {
  table-layout: fixed;
  width: 1880px;
  line-height: calc(34px - 1px);
  position:relative;
  top: 47px;

  label {
    background: #404040;
    color: white;
    display : block;
    font-weight: normal;
    padding-left: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
  td {
    border: 1px solid @lightgrey;
    padding-left: 10px;
    vertical-align: top;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
}

.col-route {
  width: 100px;
}

.col-service {
  width: 500px;
}

.col-occupancy {
  width: 100px;
}

.col-boarded {
  width: 100px;
}

.col-alighted {
  width: 100px;
}

.col-message {
  width: 980px;
}

.orbsVehicleStatusEmptyActiveFaultsTable {
  width: 1880px;
  height: 235px;
  background: #EEE;
  position:relative;
  top: 0px;
}

.orbsVehicleStatusEmptyActiveFaultsTableText {
  left:18px;
  position: absolute;
}

.orbsVehicleStatusEmptyTripStatusTable {
  width: 1880px;
  height: 235px;
  background: #EEE;
  position:relative;
  top: 46px;
}

.orbsActiveFaultsContainer {
  position: relative;
  top: 50px;
  line-height: calc(34px - 1px);

  .headerPositioned {
    padding-top: 34px;
    position: relative;
    width: 1880px;
    background: #404040;
    height: 0px;
  }

  .scrollContainer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 288px;
  }

  label {
    display : block;
    font-size: small;
    font-weight: normal;
  }
  table {
    table-layout: fixed;
    width : 100%;
  }
  th {
    background-color: #3c3c3c;
    color: white;
    border: 1px solid @lightgrey;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
  }
  th > div {
    top: 0;
  }
  th > .th-inner {
    margin-left: 0px;
	line-height : 31px;
	cursor : pointer;
  }
  td {
    background-color: white;
    position: relative;
    border: 1px solid @lightgrey;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    padding-left: 10px !important;
  }
  .col-small {
    width : 50px
  }
}

.orbsBallCenter {
  left: 8% !important;
  top: 5px;
}

.orbsBallContainer {
  text-align : center;
}
.orbsBall {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin: 0;
  border-radius: 50%;
  position: relative;
  left: 15%;

}

.orbsBall.old-stamp{
 font-size: 140%;
}

.orbsBall.ok {
  background: radial-gradient(circle at 20% 20%, #52CC52, #5F7D25 100%);
}

.orbsBall.medium {
  background: radial-gradient(circle at 20% 20%, yellow, #F0A132);
  background-color: #FFC;
}

.orbsBall.high {
  background: radial-gradient(circle at 20% 20%, #FF5F5A, #851000 100%);
  background-color: #F2DCDA;
}

.orbsBall.low {
  background: radial-gradient(circle at 20% 20%, #789CF1, #002895 100%);
}

.orbsBall.inActive {
  background: radial-gradient(circle at 20% 20%, #EAECF1, #747781 100%);
}

.orbsLevel.high {
  color: #DA5758;
}

.orbsLevel.medium {
  color: #F5CC1A;
}

.orbsLevel.ok {
  color: #64BF50;
}
.orbsLevel.low{
  color: #506CF0;
}

.orbsLevel.inActive{
  color: #DEDDE6;
}

.orbsLevelBg {
  background-color: @lighter-grey;
  padding-bottom: 3px;
}

.orbsLevelBg.high {
  background-color: #F2DCDA;
}

.orbsLevelBg-high-filter {
  background-color: #F28D82 !important;
}

.orbsLevelBg.medium {
  background-color: #FFC;
}

.orbsLevelBg-medium-filter {
  background-color: #FCFFA5 !important;
}

.orbsLevelBg.ok {
  background-color: #EBF1DF;
}

.orbsLevelBg-ok-filter {
  background-color: #D1F1AB !important;
}

.orbsLevelBg.low {
  background-color: #CEDDF1;
}

.orbsLevelBg-low-filter {
  background-color: #90D1F1 !important;
}

.orbsLevelBg.inActive {
  background-color: #DEDDE6;
}

.orbsLevelBg-inActive-filter {
  background-color: #BBBAC3 !important;
}

.inActiveTh{
  padding-top: 4px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 80%;
}

div#organisationDepotChartsContainer{
  margin-top: 40px;height: 450px;overflow-y: auto;
}

div#organisationStatusFilterControlContainer{
  position:absolute;
  bottom:70px;
  margin-left:20px;
}

div#organisationChartsContainer {
  position: absolute;top: 0;width: 100%;
}

div#fleetStatusFilterControlContainer{
  margin:0;
}

div#fleetStatusVehicleControlContainer {
  float: left; width: 20%; margin-left: 7%; overflow: hidden;
}

div#fleetStatusModeDisplayControlContainer{
  float:left;width: 20%;overflow: hidden;margin-left:7% ;
}

div.depot-vehicle-state-selection {
  border: 1px solid darkgray;
  padding: 30px 5px 30px 10px;
  width: 90%;
  height: 100%;
  div{
    height: 30px;
  }

  label span{
    font-size: smaller;

  }
  label > span > span.text-align-right{
    float: right;
    font-size: small;
    padding-right: 60px;

  }
}
