/*
Colour Picker
*/
.selected-colorpalette-item {
    width: 16px;
    height: 16px;
}

.k-colorpalette .k-item {
    width: 16px;
    height: 16px;
    border: solid;
    border-color: black;
    border-width: 1px;
}

.k-colorpalette .k-palette {
    border-collapse: separate;
    border-spacing: 6px;
}

/* Vehicle History */
.history-moveable-search-result-view .k-state-selected {
    background-color: rgb(231, 216, 111);
    color: #333;
}

.history-moveable-search-result-view td.k-state-focused {
    box-shadow: inset 0 0 5px 5px rgb(231, 216, 111);
}
