.glyphicon-spin {
  -webkit-animation: spin 1s infinite ease;
  -moz-animation: spin 1s infinite ease;
  -o-animation: spin 1s infinite ease;
  animation: spin 1s infinite ease;
}

@-moz-keyframes spin {
  from {
	-moz-transform: rotate(0deg);
  }
  to {
	-moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
	-webkit-transform: rotate(0deg);
  }
  to {
	-webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
	transform: rotate(0deg);
  }
  to {
	transform: rotate(360deg);
  }
}

.ptipsicon.disabled {
  color: #CCC;
  cursor: default;
}
