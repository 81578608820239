.user-menu {
	display: inline-block;

	.user-name {
		font-size: 14px;
	}

	.glyphicon-user {
		.user-menu-icon(19px, 3px);
	}

	.app-version {
		padding: 5px 20px;
		font-size: 10px;
		color: @gray-light;
	}

	.app-version-title {
		font-size: 12px;
	}

	ul > li {
		.ptipsicon, .glyphicon {
			.user-menu-icon(18px, 3px);
		}
	}

	.dropdown-menu .divider {
		position: relative;
		left: 46px;
		width: calc(100% - 46px);
		margin: 5px 0;
	}
}

.user-menu-icon(@font-size, @position-top) {
	font-size: @font-size;
	margin-right: 8px;
	top: @position-top;
	position: relative;
}
