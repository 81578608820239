.col-organistaion {
	width: 90px;
}
.depot-cell {
  width: 90px;
}
.user-id-cell {
  width: 90px;
}
.shift-cell{
  width: 70px;
}
.vehicle-cell{
  width: 60px;
}
.contract-cell{
  width: 70px;
}
.route-cell{
  width: 80px;
}
.time-cell{
  width: 120px;
}
.reason-cell{
  width: 80px;
}
.description-cell{
  width: 240px;
}
.cancel-cell {
  width: 120px;
}
.tripid-cell{
  width: 70px;
}