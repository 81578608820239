.tracking-buses-status-display {
    background: white;
    padding: 0.5em;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 25px;
    margin-top: 1px;
    margin-bottom: 5px;
    display: inline-block;
}

.tracking-buses-status-display .title {
    font-size: 120%;
    position: relative;
    top: -3px;
}

.tracked-buses-status-panel {
    height: calc(100% - 44px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 0px;
}

.tracked-bus-display-control {
    cursor: pointer;
    background: #FFA403;
    font-size: 100%;
    padding: 1px 1px 1px 4px;
    margin-left: 3px;
}

.tracked-bus-display-control .ptipsicon {
    color: white;

    &.followed {
        color: yellow;
    }
}

.tracking-buses-status-display .ptipsicon:before {
    font-size: 14px;
    padding-right: 2px;
}

.tracking-buses-status-display .toggle-tracked-bus-status-panel {
    background: #FFC053;
    color: black;
    padding: 1px 7px;
    font-weight: normal;
}

.tracked-bus-display-control.panelHidden .toggle-tracked-bus-status-panel {
    background: white;
}

.tracked-bus-status-panel {
    background: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 1em;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 280px;
    height: 258px;
}

.tracked-bus-status-panel,
.tracked-bus-status-panel * {
    box-sizing: border-box;
}

.tracked-bus-progress-block {
    display: flex;
}

.tracked-bus-progress-block .info-window-column {
    padding-right: 2em;
}

.info-window-next-stop {
    height: 52px;
}

.tracked-bus-progress-block .info-window-column dl {
    margin-bottom: 5px;
}

.tracked-bus-status-panel .tracking-button-container {
    position: relative;
    top: -2ex;
    button {
        display: block;
        width: 107px;
        margin: 3px 0 3px 5px;
        text-align: left;
    }
}

.tracked-bus-progress-block .route-progress-box {
    flex-grow: 1;
    vertical-align: top;
}
