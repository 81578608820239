.timeDistanceViewContainer {
    padding: 10px;
    margin: 0px;
    width: calc(100% - 40px);
    height: calc(100% - 20px);
    overflow-x: hidden;
    overflow-y: hidden;
    display: block !important;
    white-space: nowrap !important;
    float: none !important;
}

.timeDistanceViewContainer {
    .timeDistanceChart {
        width: 100%;
        height: calc(100% - 80px);
    }
}

.ptcharts-container {
    background-color: white;
}

.tdd-well {
    padding-top: 0px;
    padding-bottom: 0px;
    position: absolute;
    width: calc(100% - 160px);
    top: 75px;
    right: 40px;
    background-color: white;
    border: 1px solid whitesmoke;
    border-radius: 0;
    box-shadow: 0 1px 5px -1px grey;

    .circular-border, .loading {
        border-radius: 5px;
        display: inline-block;
        padding:0.7em;
        width:1.5em;
        height:1.5em;
        background-color: #444;
        color:white;
        margin-right: 5px;
        left:-8px;
        top: 3px;
        position: relative;

        i {
            font-size:18px;
            margin-left:1px;
        }
    }

    .label-container {
        display:table-cell;
        vertical-align:middle;
        margin-top: 3px;

        span {
            vertical-align: middle;
            display: table-cell;
        }

        span:last-child {
            span {
                display: table-row;
            }
        }
    }
    .controls-container {
        display:table-cell;
        vertical-align:middle;
        padding-right:10px;
    }
}

.tdd-well-bottom {
    top: calc(50% + 50px);
}

.legend-well {
    padding-top: 0px;
    padding-bottom: 10px;
    position: absolute;
    width: 150px;
    bottom: 30px;
    right: 40px;
    background-color: white;
    /* box-shadow: 0px 0px 3px 3px whitesmoke; */
    border: 1px solid grey;

    .header {
        padding-top: 10px;
        padding-bottom: 10px;

    }
}

.message-well {
    position: absolute;
    width: auto;
    height: auto;
    top: 40%;
    right: 20%;
    left:calc(20% + 40px);
    background-color: white;
    text-align: left;
    font-size:medium;
    padding:10px;

    border: 1px solid grey;

    .header {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    .glyphicon {
        font-size:xx-large;
        vertical-align: middle;
    }

    table {
        margin: 0 auto;
    }

    .routeName {
        text-decoration: underline;
    }
}

.time-distance-data-point-info.info-window {
    display: block;
}

.time-distance-vehicle-info.info-window {
    display: block;
}

.time-distance-vehicle-info #last-updated {
    float: right;
    font-size: 0.6em;
    vertical-align: top;
}
.popover.time-distance-data-point-popover {
    max-width: 100%;
    &.click-popover {
        z-index:1
    }

    &.hover-popover {
        z-index:2
    }

    .left-container {
        display:inline-block;
        padding-right: 10px;
    }
    .border-right {
        border-right: 1px solid #CFCFCF;
    }

    .right-container {
        display: inline-block;
        vertical-align: top;
        width: 150px;
    }
}

.popover.time-distance-vehicle-popover {
    max-width: 100%;
}
