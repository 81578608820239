.vehicleDetailsContainer {
    padding: 10px;
    overflow: auto;
    height: calc(100% - 20px);

    .panel {
        margin-bottom: 0;
    }

    .panel-body {
        padding: 10px;
        line-height: 120%;
    }

    .large {
        font-size: 120%;
        font-weight: 700;
    }

    .large a {
        font-size: 14px;
    }

    a {
        font-size: 12px;
    }
}

/* Vehicle Details Offset Box */
.route-progress-box {
    position: relative;
    width: 100%;
}

.route-progress-box .schedule {
    position: relative;
    background-color:#828282;
    height: 100%;
    border-right: 1px solid lightgray;
}

.summary-view-offset-separator {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.route-progress-box {
    .actual {
        position: absolute;
        top: 5px;
        bottom: 5px;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        border-right: 1px solid lightgray;
    }

    .actualBorder {
        position: absolute;
        height: 100%;
        background-color: lightgray;
    }

    .schedule-caret {
        position:absolute;
        top: -4px;
        left: -3px
    }

    .caret-border {
        position: absolute;
        top: 0px;
        height: 100%;
        border-right: 1px solid #333;
    }
}


.transitStopsBox {
    border: lightgray solid 1px;
    margin: 5px 5px 10px 0;
    height: 80px;
    overflow: auto;
}

.transitStopsBox > ul {
    margin : 5px 0;
    list-style-type: none;
    -webkit-padding-start: 10px;
}

.margin-right-10 {
  margin-right : 10px;
}

#vehicleDetails .dropdown-menu {
   margin: 20px 0 0 ;
}