.shift-report {
    height: 100%;

    .operator-name {
        font-size: @largerFontSize;
    }

    .well {
        margin: 0 5px 0 5px;
    }

    .popover {
        max-width: 50%;
    }
}

#shiftReportTreeViewTable {
    height: calc(100% - 106px);
}

#loadMoreResults {
    margin: 5px 2px;
}

.subRow {
    display: none;
}

.driverIdCell { width: 50px; }
.driverShiftCell { width: 50px; }
.driverEndedTripCell { width: 40px; }
.tripEndCell { width: 110px; }
.driverStartedTripCell { width: 40px; }
.tripStartCell { width: 110px; }
.vehicleIdCell { width: 55px; }
.reportedTripsCell { width: 85px; }
.routeCell { width: 90px; }

@media (min-width: 768px) {
    .driverEndedTripCell { width: 95px; }
    .driverStartedTripCell { width: 100px; }
}

.small-form-group {
    width: 150px;
}

.small-form-group input[type=text] {
    width: inherit;
}

.resetFormListItem {
    position: relative;
    bottom: 5px;
    font-size: 20px;
    color: black;
    cursor:pointer;
    vertical-align: middle;
    transition: color 1s ease;
}

.resetFormListItem:hover {
    color: red;
    cursor: pointer;
}

.results-summary {
    background-color: #eee;
}

.shift-report-summary-bar {
    background-color: #eee;
    padding: 5px;
    min-height: 45px;
    border-bottom: 3px solid white;
    border-top: 3px solid white;
}

.shiftReportFilterContainer {
    .input-group {
        float: right;
        width: 300px;
    }
    .radio-input-group {
        margin-top: 7px;
        width: 320px;
    }
    label {
        font-weight: normal;
        margin: 0 10px 0 2px;
    }
    input[type='radio'] {
        position: relative;
        top: 2px;
    }
}

#shift-report-help-button {
    margin-left: 2px;
}

.uib-datepicker {
    table, * {
        box-sizing: border-box;
        tr {
            button {
                margin-left: 0;
            }
        }
    }
}
