.multi-select {
  display: inline-block;

  .option.select-all {
	border: 0 solid darkgrey;
	border-bottom-width: 1px;
  }
  .dropdown-menu {
	margin-top: 0;
	padding: 5px;
	max-height: 300px;
	overflow-y: auto;
  }
  .multi-select-toggle {
	padding-right: 8px;
	padding-left: 8px;
  }
  .multi-select-search {
	margin-bottom: 8px;
  }
  .ptipsicon {
	vertical-align: middle;
	margin-right: 10px;
	font-size: 1.3em;
  }
}

.multi-select-wide > .multi-select {
  .btn-group {
	& > .btn:first-child {
	  min-width: 150px;
	}
  }
}

.border-radius-3 {
  border-radius: 3px !important;
}

.dropdown-caret {
  float: right;
  margin-top: 5px;
  margin-left: 15px !important;
}

.pt-multiselect-selected-row {
  width: 100%;
  height: 1.5em;
  padding-top: 10px;
  padding-left: 10px;
}
