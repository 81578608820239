
ptips-status-bar {
    z-index: 1;
    position: relative;
}

#statusBar {
    box-sizing: border-box;
    height: 28px;
    width: 100%;
    background-color: #d8d8d8;
    position: fixed;
    left: 0;
    bottom: 0;
    border-top: 1px solid #c4c4c4;
}

#statusBarNotification {
    position: absolute;
    bottom: 0;
    margin-right: 10px;
    right: 0;
    width: 360px;
    min-height: 26px;
    max-height: 90vh;
    border-radius: 2px;
    color: white;
    word-wrap: break-word;

    -moz-transition: opacity 0.6s ease-out;
    -ms-transition: opacity 0.6s ease-out;
    -o-transition: opacity 0.6s ease-out;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#statusBarNotificationPageScroller a,
#statusBarNotification header a {
    color: white;
    font-size: 1.3em;
}

#statusBarNotificationPageScroller a:hover,
#statusBarNotification header a:hover {
    text-decoration: none;
}

#statusBarNotification .minimiseButton {
    float: right;
    display: inline-block;
}

#statusBarNotification time {
    float: right;
    display: inline-block;
    font-size: 0.8em;
    line-height: 20px;
    margin-right: 10px;
}

#statusBarNotification h3 {
    overflow: hidden;
    width: 245px;
    padding: 0;
    font-size: 1.2em;
    display: inline-block;
    float: left;
    margin: 1px 0 2px 0;
    font-weight: 700;

    -moz-transition: max-height 0.6s ease-out, opacity 0.6s ease-out;
    -ms-transition: max-height 0.6s ease-out, opacity 0.6s ease-out;
    -o-transition: max-height 0.6s ease-out, opacity 0.6s ease-out;
    -webkit-transition: max-height 0.6s ease-out, opacity 0.6s ease-out;
    transition: max-height 0.6s ease-out, opacity 0.6s ease-out;
}

#statusBarNotification summary {
    clear: both;
    font-size: 0.9em;
}

#statusBarNotification summary,
#statusBarNotification footer {
    max-height: 0px;
    opacity: 0;

    -moz-transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
    -ms-transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
    -o-transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
    -webkit-transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
    transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
}

#statusBarNotification.open summary,
#statusBarNotification.open footer {
    max-height: 100vh;
    opacity: 1;
}

#statusBarNotification:not(.open) h3 {
    max-height: 15px;
}

#statusBarNotification.hidden {
    opacity: 0;
}

#statusBarNotificationLeftContent {
    width: 20px;
    float: left;
    padding: 2px 5px;
    font-size: 21px;
    height: 100%;
}

#statusBarNotificationRightContent {
    width: 320px;
    float: left;
    padding: 4px 5px 0 5px;
    height: 100%;
}

#statusBarNotification.info {
    /* http://www.colorzilla.com/gradient-editor/#52642a+0,819c4d+80,bed39b+100;Custom */
    border: 1px solid #52642a;

    background: #52642a; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #52642a 0%, #819c4d 80%, #bed39b 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#52642a), color-stop(80%,#819c4d), color-stop(100%,#bed39b)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #52642a 0%,#819c4d 80%,#bed39b 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #52642a 0%,#819c4d 80%,#bed39b 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #52642a 0%,#819c4d 80%,#bed39b 100%); /* IE10+ */
    background: linear-gradient(135deg,  #52642a 0%,#819c4d 80%,#bed39b 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#52642a', endColorstr='#bed39b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

#statusBarNotification.warning {
    /* http://www.colorzilla.com/gradient-editor/#ab8000+0,ffc309+80,ffcf3c+100;Custom */
    border: 1px solid #ab8000;

    background: #ab8000; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #ab8000 0%, #ffc309 80%, #ffcf3c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#ab8000), color-stop(80%,#ffc309), color-stop(100%,#ffcf3c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #ab8000 0%,#ffc309 80%,#ffcf3c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #ab8000 0%,#ffc309 80%,#ffcf3c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #ab8000 0%,#ffc309 80%,#ffcf3c 100%); /* IE10+ */
    background: linear-gradient(135deg,  #ab8000 0%,#ffc309 80%,#ffcf3c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab8000', endColorstr='#ffcf3c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

#statusBarNotification.error {
    /* http://www.colorzilla.com/gradient-editor/#923931+0,da9e99+80,e3b7b1+100;Custom */
    border: 1px solid #923931;

    background: #923931; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #923931 0%, #da9e99 80%, #e3b7b1 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#923931), color-stop(80%,#da9e99), color-stop(100%,#e3b7b1)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #923931 0%,#da9e99 80%,#e3b7b1 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #923931 0%,#da9e99 80%,#e3b7b1 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #923931 0%,#da9e99 80%,#e3b7b1 100%); /* IE10+ */
    background: linear-gradient(135deg,  #923931 0%,#da9e99 80%,#e3b7b1 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#923931', endColorstr='#e3b7b1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

#statusBarNotificationLeftContent .ptipsicon {
    float: left;
}

#statusBarNotificationPageCount {
    display: inline-block;
    float: right;
    font-size: 0.9em;
    margin-right: 5px;
}

#statusBarNotificationPageScroller {
    display: inline-block;
    float: right;
    position: relative;
    bottom: 5px;
    font-size: 1.3em;
    letter-spacing: -4px;
}

#statusBarNotificationRightContent footer {
    box-sizing: border-box;
    height: 25px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
}

.ball {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 50%;
    position: relative;
}

.ball:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    filter: blur(5px);
    z-index: 2;
}

.ball, .ball.grey {
    background: radial-gradient(circle at 20% 20%, #eee, #A5A2A2 100%);
}

.ball.green {
    background: radial-gradient(circle at 20% 20%, #52CC52, #5F7D25 100%);
}

.ball.yellow {
    background: radial-gradient(circle at 20% 20%, #FEF600, #D8AF1E 100%);
}

.ball.red {
    background: radial-gradient(circle at 20% 20%, #DE1919, #9E605E 100%);
}

.ball.blue {
    background: radial-gradient(circle at 20% 20%, #233ead, #6475b9 100%);
}

@media print {
    #statusBar {
        display: none;
    }
}