@font-family-open-sans: "Open Sans", sans-serif;
@font-family-base: @font-family-open-sans;

@media (min-width: 768px) {
  .navbar-form.navbar-right:last-child {
	margin-right: 0px;
  }
}

.tab-pane, .tabbable {
  height: 100%;
}

.nav > li > a {
  padding: 6px 5px 8px 5px;
  min-width: 30px;
}

.tab-content {
  height: calc(100% - 36px);
  /* 36px is the sum of:
	  bootstrap's 20px line height
	  .nav > li > a { padding : 7px .. 7px .. } (this file)
	  .nav-tabs > li.active > a { border: 1px } (bootstrap.css)
  */
}

.popover {
  font-size: inherit;
  &.bottom-left, &.bottom-right {
    margin-top: 2px;
  }
}

/* pretty sure bootstrap got these four wrong, override... */
.tooltip.top .tooltip-arrow {
  margin-bottom: -5px;
}

.tooltip.bottom .tooltip-arrow {
  margin-top: -5px;
}

.tooltip.right .tooltip-arrow {
  margin-left: -5px;
}

.tooltip.left .tooltip-arrow {
  margin-right: -5px;
}

/* bootstraps own margin-left on toolbar stuffs up their own tooltip calculations ... */
.btn-toolbar {
  margin-left: 0;
}

.btn, .form-group:not(:first-child) {
  margin-left: 5px;
}

.btn-group-vertical, .filter-panel-buttons {
  .btn {
    margin-left: 0px;
  }
}

.popover {
  font-family: @font-family-base;
}