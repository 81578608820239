.ptcharts-container {
  .shape {
	cursor: pointer;

	&.vehicle {
	  circle {
		stroke:rgba(255,255,255,0.9);
	  }
	  &.vehicle-ACTIVE {
		circle {
		  fill: #FFC000;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}
	  }

	  &.vehicle-LATE {
		circle {
		  fill: #0070C0;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}
	  }

	  &.vehicle-EARLY {
		circle {
		  fill: #C00000;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}
	  }

	  &.vehicle-NOT_ON_TRIP {
		circle {
		  fill: white;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}
	  }

	  &.vehicle-ON_TIME {
		circle {
		  fill: #339933;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}
	  }

	  &.vehicle-OFF_ROUTE {
		circle {
		  fill: white;
		  stroke: grey;
		  stroke-width:0px;
		  stroke-opacity: 1;
		}

		.custom path {
		  fill: red
		}
	  }

	  &.vehicle-INACTIVE {
		circle {
		  fill: grey;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}

		.custom path {
		  fill: white
		}
	  }

	  &.vehicle-DISCONNECTED {
		circle {
		  fill: grey;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}

		.custom path {
		  fill: white
		}
	  }

	  &.vehicle-BETWEEN_TRIPS {
		circle {
		  fill: #FFC000;
		  stroke-width:3px;
		  stroke-opacity: 1;
		}
	  }
	}

	rect {
	  fill-opacity: 1;

	  font-weight: bold;
	}

	text {
	  font-weight: bold;
	}
  }
}
