#bus-stop-history-right-toolbar {
    width: calc(100% - 48px);
    margin-right: 3px;
    vertical-align: middle;
}

.transitStopTreeViewTable {
    height: calc(100% - 54px);
}

.transitStopHistory {
    height: 100%;

    .scheduleTimeCell {
        width: 78px;
    }

    .tripCell { }

    .routeCell {
        width: 65px;
    }

    .rvvStartDateCell {
        width: 78px;
    }

    .rvvExpiryDateCell {
        width: 85px;
    }

    .shiftCell { }

    .shiftStartDateCell {
        width: 81px;
    }

    .shiftExpiryDateCell {
        width: 90px;
    }

    .reportedBusesCell { }

    .lastReportedCell {
        width: 102px;
    }
}
