@earlyVehicleColour: #C00000;
@lateVehicleColour: #0070C0;
@onTimeVehicleColour: #339933;
@deadRunningVehicleColour: #FFC000;
@inactiveVehicleColour: grey;
@clearColour: rgba(0,0,0,0);

.heavy .deviation {
  font-weight: bold;
}

.deviation-suffix {
  font-size: xx-small;
}

.colour-vehicle-EARLY {
  color: @earlyVehicleColour !important;
}

.colour-vehicle-LATE {
  color: @lateVehicleColour !important;
}

.colour-vehicle-ON_TIME {
  color: @onTimeVehicleColour !important;
}

.bg-grad-colour-vehicle-NONE {
  background-color: @clearColour !important;
}

.bg-grad-colour-vehicle-EARLY {
  background-color: @earlyVehicleColour;
  .quick-gradient(top, 0.2);
}

.bg-grad-colour-vehicle-LATE {
  background-color: @lateVehicleColour;
  .quick-gradient(top, 0.2);
}

.bg-grad-colour-vehicle-ON_TIME {
  background-color: @onTimeVehicleColour;
  .quick-gradient(top, 0.2);
}