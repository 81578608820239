@tooltip-border-color: #62626b;

.tooltip {
    display: block;
    filter: alpha(opacity=1);
    font-size: 11px;
    opacity: 1;
    padding: 5px;
    position: absolute;
    visibility: visible;
    z-index: 1020;
}
.tooltip-inner {
    background-color: #F8F8F8 !important;
    border-radius: 4px;
    border: 1px solid @tooltip-border-color;
    color: #000000;
    display: inline-block;
    margin-left: 10px;
    max-width: 200px;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
}
.tooltip.in {
    filter: alpha(opacity=80);
    opacity: 1;
}
.tooltip.top {
    margin-top: -2px;
    .tooltip-arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid @tooltip-border-color;
        bottom: 5px;
        left: 50%;
        margin-left: 0px;
    }
}
.tooltip.top-right {
    left: 10px !important;
    margin-top: -2px;
    top: 20px;
    .tooltip-inner {
        border-radius: 5px 5px 5px 0px;
        text-align: left;
    }
    .tooltip-arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid @tooltip-border-color;
        bottom: 5px;
        left: 50%;
        margin-left: 0px;
    }
}
.tooltip.bottom-right {
    .tooltip-arrow {
        left: 50%;
        top: auto;
    }
}
.tooltip.right {
    margin-left: 2px;
    .tooltip-arrow {
        border-bottom: 5px solid transparent;
        border-right: 5px solid @tooltip-border-color;
        border-top: 5px solid transparent;
        left: 0;
        margin-top: -10px;
        top: 50%;
    }
}
.tooltip.bottom {
    margin-top: 2px;
    .tooltip-arrow {
        border-bottom: 5px solid @tooltip-border-color;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        left: 50%;
        margin-left: -5px;
        top: 5px;
    }
}
.tooltip.left {
    margin-left: -2px;
    .tooltip-arrow {
        border-bottom: 5px solid transparent;
        border-left: 5px solid @tooltip-border-color;
        border-top: 5px solid transparent;
        margin-top: -5px;
        right: 0;
        top: 50%;
    }
}
.tooltip-arrow {
    height: 0;
    position: absolute;
    width: 0;
}
