.slider-label {
	text-align: center;
  	font-size:18px;
}

rzslider {
  margin-top:20px;
  .rz-pointer:after {
	background: none !important;
  }

  .rz-limit {
	display:none;
  }

  .rz-ticks {
	top:-13px;
	padding: 0 15px;

	.tick {
	  width: 2px;
	  height: 38px;

	  visibility:hidden;
	  &.default {
		visibility:visible;
	  }

	  span.small-title {
		top: 25px;
		margin-left: 10px;
	  }
	}

  }

  .rz-bubble {
	bottom:-48px;
	font-size:18px;
  }

  .rz-pointer {
	top:-11px;
	background: rgba(196,196,196,1);
	background: -moz-radial-gradient(center, ellipse cover, rgba(196,196,196,1) 0%, rgba(189,189,189,1) 14%, rgba(219,219,219,1) 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(196,196,196,1)), color-stop(14%, rgba(189,189,189,1)), color-stop(100%, rgba(219,219,219,1)));
	background: -webkit-radial-gradient(center, ellipse cover, rgba(196,196,196,1) 0%, rgba(189,189,189,1) 14%, rgba(219,219,219,1) 100%);
	background: -o-radial-gradient(center, ellipse cover, rgba(196,196,196,1) 0%, rgba(189,189,189,1) 14%, rgba(219,219,219,1) 100%);
	background: -ms-radial-gradient(center, ellipse cover, rgba(196,196,196,1) 0%, rgba(189,189,189,1) 14%, rgba(219,219,219,1) 100%);
	background: radial-gradient(ellipse at center, rgba(196,196,196,1) 0%, rgba(189,189,189,1) 14%, rgba(219,219,219,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c4c4c4', endColorstr='#dbdbdb', GradientType=1 );
  }
  .rz-bar {
	height:10px;
	border-radius:5px;
	background: rgba(168,166,168,1);
	background: -moz-linear-gradient(top, rgba(168,166,168,1) 0%, rgba(168,166,168,1) 32%, rgba(199,199,199,1) 64%, rgba(246,246,246,1) 89%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(168,166,168,1)), color-stop(32%, rgba(168,166,168,1)), color-stop(64%, rgba(199,199,199,1)), color-stop(89%, rgba(246,246,246,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(168,166,168,1) 0%, rgba(168,166,168,1) 32%, rgba(199,199,199,1) 64%, rgba(246,246,246,1) 89%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(168,166,168,1) 0%, rgba(168,166,168,1) 32%, rgba(199,199,199,1) 64%, rgba(246,246,246,1) 89%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(168,166,168,1) 0%, rgba(168,166,168,1) 32%, rgba(199,199,199,1) 64%, rgba(246,246,246,1) 89%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(168,166,168,1) 0%, rgba(168,166,168,1) 32%, rgba(199,199,199,1) 64%, rgba(246,246,246,1) 89%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8a6a8', endColorstr='#ffffff', GradientType=0 );
  }

  &.late-slider {
	.rz-bar.rz-selection {
	  background: rgba(116,151,204,1);
	  background: -moz-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%);
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(116,151,204,1)), color-stop(15%, rgba(116,151,204,1)), color-stop(50%, rgba(83,126,191,1)), color-stop(85%, rgba(116,151,204,1)), color-stop(100%, rgba(116,151,204,1)));
	  background: -webkit-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%);
	  background: -o-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%);
	  background: -ms-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%);
	  background: linear-gradient(to bottom, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7497cc', endColorstr='#7497cc', GradientType=0 );
	  border-radius:5px;
	}

	.tick {
	  background: rgba(116,151,204,1) !important;
	  background: -moz-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%) !important;
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(116,151,204,1)), color-stop(15%, rgba(116,151,204,1)), color-stop(50%, rgba(83,126,191,1)), color-stop(85%, rgba(116,151,204,1)), color-stop(100%, rgba(116,151,204,1))) !important;
	  background: -webkit-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%) !important;
	  background: -o-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%) !important;
	  background: -ms-linear-gradient(top, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%) !important;
	  background: linear-gradient(to bottom, rgba(116,151,204,1) 0%, rgba(116,151,204,1) 15%, rgba(83,126,191,1) 50%, rgba(116,151,204,1) 85%, rgba(116,151,204,1) 100%) !important;
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7497cc', endColorstr='#7497cc', GradientType=0 ) !important;

	  span.small-title {
		color:rgba(116,151,204,1);
	  }
	}
  }

  &.early-slider {
	.rz-bar.rz-selection {
	  background: rgba(181,87,82,1);
	  background: -moz-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%);
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181,87,82,1)), color-stop(15%, rgba(181,87,82,1)), color-stop(50%, rgba(152,61,54,1)), color-stop(85%, rgba(181,87,82,1)), color-stop(100%, rgba(181,87,82,1)));
	  background: -webkit-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%);
	  background: -o-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%);
	  background: -ms-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%);
	  background: linear-gradient(to bottom, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b55752', endColorstr='#b55752', GradientType=0 );
	  border-radius:5px;
	}

	.tick {
	  background: rgba(181,87,82,1) !important;
	  background: -moz-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%) !important;
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181,87,82,1)), color-stop(15%, rgba(181,87,82,1)), color-stop(50%, rgba(152,61,54,1)), color-stop(85%, rgba(181,87,82,1)), color-stop(100%, rgba(181,87,82,1))) !important;
	  background: -webkit-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%) !important;
	  background: -o-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%) !important;
	  background: -ms-linear-gradient(top, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%) !important;
	  background: linear-gradient(to bottom, rgba(181,87,82,1) 0%, rgba(181,87,82,1) 15%, rgba(152,61,54,1) 50%, rgba(181,87,82,1) 85%, rgba(181,87,82,1) 100%) !important;
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b55752', endColorstr='#b55752', GradientType=0 ) !important;

	  span.small-title {
		color:rgba(181,87,82,1);
	  }
	}
  }
}
