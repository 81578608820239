/*
 * Push buttons: generated by http://blog.koalite.com/bbg/ as follows:
 *   - Class Name: btn-pushed
 *   - Text: #ffffff
 *   - Background: #555555
 *   - Border: #000000
 *   - Active Background: #777777
 */

.btn-pushed {
    color: #FFFFFF;
    background-color: #555555;
    border-color: #000000;
}

.btn-pushed:hover,
.btn-pushed:focus,
.btn-pushed:active,
.btn-pushed.active,
.open .dropdown-toggle.btn-pushed {
    color: #FFFFFF;
    background-color: #777;
    border-color: #000000;
}

.btn-pushed:active,
.btn-pushed.active,
.open .dropdown-toggle.btn-pushed {
    background-image: none;
}

.btn-pushed.disabled,
.btn-pushed[disabled],
fieldset[disabled] .btn-pushed,
.btn-pushed.disabled:hover,
.btn-pushed[disabled]:hover,
fieldset[disabled] .btn-pushed:hover,
.btn-pushed.disabled:focus,
.btn-pushed[disabled]:focus,
fieldset[disabled] .btn-pushed:focus,
.btn-pushed.disabled:active,
.btn-pushed[disabled]:active,
fieldset[disabled] .btn-pushed:active,
.btn-pushed.disabled.active,
.btn-pushed[disabled].active,
fieldset[disabled] .btn-pushed.active {
    background-color: #555555;
    border-color: #000000;
}

.btn-pushed .badge {
    color: #555555;
    background-color: #FFFFFF;
}