
.genisys {
  margin-left: calc(50% - 10px);
  width: 20px;
  height: 32px;
  background: #FFF url("@{img-path}/genisys.png") no-repeat;
}
.genisys.genisys-animate {
  -webkit-animation:3s rotateRight infinite linear;
}

@-webkit-keyframes rotateRight{
  0%{ -webkit-transform:rotate(0deg); -webkit-transform-origin:50% 50%; }
  100%{ -webkit-transform:rotate(360deg); }
}
