.historyMap {
    height: 100%;
}

/*Map Toolbar*/
.history-toolbar-margins {
    margin-top: 5px;
    margin-right: 5px;
}

/*Search Form*/

.history-search-form {
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 0;
}

.history-search-form .form-group-date-picker {
    //width: 165px;
}

.history-search-form .form-control-kendo-element {
    width: 165px;
    box-sizing: content-box;
    padding: 0px;
}

.history-search-form .form-group {
    padding-bottom: 10px;
}

.from-date {
    width: 115px !important;
}

/*styles apply when search form in side view*/
.history-side-search-results-panel .history-search-form .form-group {
    margin-right: 10px;
}

/*individual form elements*/
#searchVehicleID, #fromDate, #toDate {
    width: 115px;
}


#vehicleHistorySearchSubmitButton {

}

/*Search Results*/
.history-selected-search {
    inset: 0px -1px 2px rgba(133, 133, 133, 0.5);

    #VehicleTrackerCloseVehicle {
        font-size: 22px;
        vertical-align: middle;
        cursor: pointer;
        color:black;
        transition: color 1s ease;

        &:hover {
            color: red;
            cursor: pointer;
        }
    }
}

.history-selected-search .form-group {
    padding: 10px;
    margin: 0px;
}

.vehicle-history-result-table .form-group {
    padding: 10px;
    margin: 0px;
}

#historyTopPanel .k-splitter .k-scrollable {
    overflow: visible;
}

/* to control scrolling */
.history-moveable-search-result-view {
    overflow-y: hidden;
    overflow-x: hidden;
    height: calc(100% - 70px);
}

.historySearchResultsStyle {
    overflow-x: auto;
}

.collapsedHistorySearchResultsStyle {
    overflow-y: scroll;
}

.expandedHistorySearchResultsStyle {
    overflow-y: hidden;
}

/* accordion panel body */
.historySearchResultsStyle .panel-body{
    padding: 0px;
}

.historySearchResultsStyle .panel-heading {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

/* History Layer Options Dropdown View */

.map-toolbar-popover {
    max-width: 600px;
    width: 350px;
}

#vehicleHistoryTrackerLayerOptions input[type=checkbox], #vehicleHistoryTrackerMapLayerOptions input[type=checkbox] {
    margin-right: 10px;
}

#vehicleHistoryTrackerMapLayerOptions, #vehicleHistoryTrackerLayerOptions {
    margin-top: 10px;
    margin-bottom: 10px;
}

#vehicleHistoryTrackerLayerOptions {
    border-left: 1px solid #333;
}

#vehicleHistoryTrackerLayerOptions .form-group {
    margin-top: 10px;
    margin-bottom: 0px;
}

#vehicleHistoryTrackerLayerOptions #distanceGapLayerInput {
    padding-right: 0px;
}

#vehicleHistoryTrackerLayerOptions #distanceGapLabel {
    text-align: left;
    padding-left: 5px;
}

.history-tracker-notice {
    padding: 10px 0 10px 10px;
    margin: 10px;
    margin-bottom: 0;
}
.history-tracker-notice-tsn {
    padding: 10px 0 10px 10px;
    margin: 15px;
    margin-bottom: 0;
    float: left;
}
