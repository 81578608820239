.vehicle-summary-view {
  height: 100%;
  overflow: auto;

  .filter-panel-standard-filters-status {
	padding: 10px;

	.disabled {
	  opacity: 0.5;
	}

  }
}

.summary-table {
  .vehicle-menu-item {
	font-weight: bold;
  }
}

.summary-table-filter-btn {
  font-size: 15px;
  padding: 6px 9px;
  height: 16px;
  line-height: @line-height-base;
}
.vehicle-summary-toolbar-soften {
  color: darkgrey;
}

.vehicle-summary-form .multi-select .dropdown-menu {
  max-height: 210px;
  font-size: inherit;
}

.vehicle-summary-form .multi-select .dropdown-menu label {
  font-weight: inherit;
}


#vehicle-summary-help-button {
  padding: 6px 9px;
  height: 16px;
  span {
	position: relative;
	bottom: 3px;
  }
}

