#btn-filter-depot {
    margin-left: 0;
}

#btn-filter-route {
    margin-left: 0;
}

.filter-panel {
    .filter-btn-group {
        width: 300px;
    }

    .btn-group {
        padding-bottom: 10px;
    }

    .filter-panel-content {
        padding: 10px;
        min-width: 256px;
        font-size: 9px;

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }

	    .filter-panel-subtext {
		  	margin-bottom: 10px;
		  	padding: 5px;
		  	font-size: smaller;
		}

        .filter-panel-body select {
            min-height: 210px;
            margin-bottom: 10px;
            width: 100%;
            box-shadow: none;
        }

        .filter-panel-buttons {
            height: 34px;
        }
    }

    .filter-btn {
        width: 205px;
    }

    .filter-btn-caret {
        width: 25px;
    }

    .filter-btn-single {
        padding-right: 0;

        .filter-btn {
            padding-right: 12px;
        }
    }
}

.filter-error {
    padding:5px;
    width: 245px;
    margin: 0 0 10px 0;
}

.filter-badge-container {
  padding-top: 8px;
}

.global-filter-badge-container {
  padding-top: 1px;
}

.dynamic-filter-badge-container {
  padding-top: 2px;
}

.filter-badge {
    min-width: 80px;
    cursor: pointer;
    background-color: #FFA403;
    color: #FFF;
    font-weight: normal;
    padding: 1px;
    vertical-align: middle;
    max-height: 400px;
    min-height: 15px;
    margin-bottom: 2px;

    .filter-badge-type {
        margin: 1px 6px;
        line-height: 12px;
        font-size: 11px;
    }

    .popover {
        color: #333;
    }
}

.filter-badge.disabled {
    background-color: #D8D8D8;
}

.filter-badge-content {
    max-height: 400px;
    overflow-y: auto;
    font-size: 14px;
}

.popover > .filter-error {
    padding:5px;
    margin: 10px 10px 0 10px;
}

.popover-container {
    display: inline-block;
    padding-left: 15px;
}

.popover-container .popover {
    min-width: 120px;
}

.popover-container .popover.bottom {
    margin-top: 5px;
}

.popover-container.popover-overflow .filter-badge-content {
    overflow-y: scroll;
}

.filter-panel.outer-filter.popover {
    max-width: 950px;
//    left: 0px !important;
}

.filter-panel.outer-filter.popover > .arrow {
//    left: 20% !important;
}

.filter-panel-standard-filters {
    width: 580px;
    float: left;
}

.filter-panel-standard-filters-left {
    width: 290px;
    float: left;
}

.filter-panel-standard-filters-right {
    width: 290px;
    float: left;
}

.filter-panel-my-filters {
    width: 290px;
    float: left;
}

.outer-filter > .filter-panel-content > .filter-panel-body {
    min-height: 240px;
    clear: both;
}

.outer-filter > .filter-panel-content > .filter-panel-body h3 {
    font-size: 14px;
    margin-top: 5px;
    padding-bottom: 10px;
}

.filter-panel-standard-filters-bus-details {
    width: 258px;

    i {
        font-size: 20px;
        float: left;
    }

    label span {
        font-weight: normal;
        display: block;
        float: left;
        margin-left: 5px;
        margin-top: 3px;
    }

    hr {
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

.filter-panel-standard-filters-status {
    width: 258px;

    i {
        font-size: 20px;
        float: left;
        visibility: hidden;
    }

    label.on-off span {
        font-weight: normal;
        display: block;
        float: left;
        margin-left: 5px;
    }

    input.on-off ~ label.on-off {
        background-position: top right;
    }

    > div {
        margin-bottom: 5px;
    }

    .checkboxOptionCaption {
        font-size: 0.9em;
        margin-left: 0;
    }
}

.filter-panel-standard-filters-trip-assignment {
    margin-top: 10px;
}

.filter-panel-standard-filters-trip-assignment i {
    visibility: hidden;
}

.filter-panel-standard-filters-trip-assignment label {
    font-weight: normal;
    display: block;
    float: left;
    padding: 3px;
    margin-bottom: 0;
}

.filter-panel-standard-filters-trip-assignment label.on-off {
    padding: 0;
}

.filter-panel-standard-filters-trip-assignment input[type=checkbox] {
    float: left;
}

.filter-panel-my-filters {
    border-left: 1px solid #eee;
    padding-left: 20px;
    min-height: 280px;
}

.filter-panel-header {
    position: absolute;
    right: 10px;
}

.filter-panel-header i {
    float: right;
    font-size: 20px;
    cursor: pointer;
    color: #444;

    &:hover {
        color: #000;
    }
}

.filter-panel-standard-filters-status .dropdown-menu {
    font-size: 13px;
}

.lateStatusIcon { .circle(@blue); }
.earlyStatusIcon { .circle(@red); }
.onTimeStatusIcon { .circle(@green); }
.betweenTripsStatusIcon { .circle(@yellow); }
.unidentifiedTripStatusIcon { .circle(@white); }
.offRouteStatusIcon { .splitCircle(@red, @white); }

.lateStatusIconSmall { .circle(@blue, 3px); }
.earlyStatusIconSmall { .circle(@red, 3px); }
.onTimeStatusIconSmall { .circle(@green, 3px); }
.disabledStatusIconSmall { .circle(#D8D8D8, 3px, 1px solid #C7C7C7); }

.filter-panel-standard-filters-status .multi-select .btn-group {
    padding-bottom: 0;
}
.global-filter-tooltip-helper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

#globalFilter {
    padding-left: 10px;
    .btn-group > .btn + .dropdown-toggle {
        padding-right: 3px;
        padding-left: 6px;
    }
}

.nav {
    .btn {
        padding: 6px 10px;
        .caret {
            margin-left: -4px;
        }
    }
}
