.ptcharts-container {
  .zoom-panel {
    cursor: move;
    pointer-events: all;
    fill: rgba(245, 242, 194, 0.16);;
    stroke: grey;
    stroke-width: 1px;
    shape-rendering: optimizeSpeed;
    stroke-opacity: 0.5;
  }
}
