/**
 * Created by morrowd on 5/08/2014.

 Bootstrap build version: 3.0.3

    The styles defined here allow a navbar to be responsive w.r.t. its container rather than the screen. See http://confluence.transport.nsw.gov.au/display/PTIPS/Container+Responsive+Navbar for details.
 */

/*@media (min-width: 768px) {*/
.navbar-cr-right-expanded .dropdown-menu-expanded {
    right: 0 !important;
    left: auto !important;
}
/*}*/

.navbar-cr {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.navbar-cr:before,
.navbar-cr:after {
    display: table;
    content: " ";
}

.navbar-cr:after {
    clear: both;
}

.navbar-cr:before,
.navbar-cr:after {
    display: table;
    content: " ";
}

.navbar-cr:after {
    clear: both;
}

/*@media (min-width: 768px) {*/
.navbar-cr-expanded {
    border-radius: 4px !important;
}
/*}*/

.navbar-cr-header:before,
.navbar-cr-header:after {
    display: table;
    content: " ";
}

.navbar-cr-header:after {
    clear: both;
}

.navbar-cr-header:before,
.navbar-cr-header:after {
    display: table;
    content: " ";
}

.navbar-cr-header:after {
    clear: both;
}

/*@media (min-width: 768px) {*/
.navbar-cr-header-expanded {
    float: left !important;
}
/*}*/

.navbar-cr-collapse {
    max-height: 340px;
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-overflow-scrolling: touch;
}

.navbar-cr-collapse:before,
.navbar-cr-collapse:after {
    display: table;
    content: " ";
}

.navbar-cr-collapse:after {
    clear: both;
}

.navbar-cr-collapse:before,
.navbar-cr-collapse:after {
    display: table;
    content: " ";
}

.navbar-cr-collapse:after {
    clear: both;
}

.navbar-cr-collapse.in {
    overflow-y: auto;
}

/*@media (min-width: 768px) {*/
.navbar-cr-collapse-expanded {
    width: auto !important;
    border-top: 0 !important;
    box-shadow: none !important;
}
.navbar-cr-collapse-expanded.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0 !important;
    overflow: visible !important;
    visibility: visible !important;
}
.navbar-cr-collapse-expanded.in {
    overflow-y: visible !important;
}
/*}*/

.container > .navbar-cr-header,
.container > .navbar-cr-collapse {
    margin-right: -15px;
    margin-left: -15px;
}

/*@media (min-width: 768px) {*/
.container > .navbar-cr-header-expanded,
.container > .navbar-cr-collapse-expanded {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
/*}*/

.navbar-cr-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
}

.navbar-cr-brand {
    float: left;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-cr-brand:hover,
.navbar-cr-brand:focus {
    text-decoration: none;
}

/*@media (min-width: 768px) {*/
.navbar-cr-expanded > .container .navbar-cr-brand-expanded {
    margin-left: -15px !important;
}
/*}*/

.navbar-cr-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.navbar-cr-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-cr-toggle .icon-bar + .icon-bar {
    margin-top: 4px;
}

/*@media (min-width: 768px) {*/
.navbar-cr-toggle-expanded {
    display: none !important;
}
/*}*/

.navbar-cr-nav {
    margin: 7.5px -15px;
}

.navbar-cr-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

/*@media (max-width: 767px) {*/
.navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed {
    position: static !important;
    float: none !important;
    width: auto !important;
    margin-top: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}
.navbar-cr-nav-collapsed .open .dropdown-menu > li > a,
.navbar-cr-nav-collapsed .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px !important;
}
.navbar-cr-nav-collapsed .open .dropdown-menu > li > a {
    line-height: 20px !important;
}
.navbar-cr-nav-collapsed .open .dropdown-menu > li > a:hover,
.navbar-cr-nav-collapsed .open .dropdown-menu > li > a:focus {
    background-image: none !important;
}
/*}*/

/*@media (min-width: 768px) {*/
.navbar-cr-nav-expanded {
    float: left !important;
    margin: 0 !important;
}
.navbar-cr-nav-expanded > li {
    float: left !important;
}
.navbar-cr-nav-expanded > li > a {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.navbar-cr-nav-expanded.navbar-cr-right:last-child {
    margin-right: -15px !important;
}
/*}*/

/*@media (min-width: 768px) {*/
.navbar-cr-left-expanded {
    float: left !important;
}
.navbar-cr-right-expanded {
    float: right !important;
}
/*}*/

.navbar-cr-form {
    padding: 10px 15px;
    margin-top: 8px;
    margin-right: -15px;
    margin-bottom: 8px;
    margin-left: -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
}

/*@media (min-width: 768px) {*/
.navbar-cr-form-expanded .form-group-expanded {
    display: inline-block !important;
    margin-bottom: 0 !important;
    vertical-align: middle !important;
}
.navbar-cr-form-expanded .form-control-expanded {
    display: inline-block !important;
    width: auto !important;
    vertical-align: middle !important;
}
.navbar-cr-form-expanded select.form-control-expanded {
    width: auto !important;
}
.navbar-cr-form-expanded .radio-expanded,
.navbar-cr-form-expanded .checkbox-expanded {
    display: inline-block !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.navbar-cr-form-expanded .radio-expanded input[type="radio"],
.navbar-cr-form-expanded .checkbox-expanded input[type="checkbox"] {
    float: none !important;
    margin-left: 0 !important;
}
/*}*/

/*@media (max-width: 767px) {*/
.navbar-cr-form-collapsed .form-group-collapsed {
    margin-bottom: 5px !important;
}
/*}*/

/*@media (min-width: 768px) {*/
.navbar-cr-form-expanded {
    width: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    border: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.navbar-cr-form-expanded.navbar-cr-right:last-child {
    margin-right: -15px;
}
/*}*/

.navbar-cr-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.navbar-cr-nav > li > .dropdown-menu {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.navbar-cr-nav.pull-right > li > .dropdown-menu,
.navbar-cr-nav > li > .dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.navbar-cr-btn {
    margin-top: 8px;
    margin-bottom: 8px;
}

.navbar-cr-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-cr-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px;
}

.navbar-cr-text {
    margin-top: 15px;
    margin-bottom: 15px;
}

/*@media (min-width: 768px) {*/
.navbar-cr-text-expanded {
    float: left !important;
    margin-right: 15px !important;
    margin-left: 15px !important;
}
.navbar-cr-text-expanded.navbar-cr-right:last-child {
    margin-right: 0 !important;
}
/*}*/

.navbar-cr-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.navbar-cr-default .navbar-cr-brand {
    color: #777777;
}

.navbar-cr-default .navbar-cr-brand:hover,
.navbar-cr-default .navbar-cr-brand:focus {
    color: #5e5e5e;
    background-color: transparent;
}

.navbar-cr-default .navbar-cr-text {
    color: #777777;
}

.navbar-cr-default .navbar-cr-nav > li > a {
    color: #777777;
}

.navbar-cr-default .navbar-cr-nav > li > a:hover,
.navbar-cr-default .navbar-cr-nav > li > a:focus {
    color: #333333;
    background-color: transparent;
}

.navbar-cr-default .navbar-cr-nav > .active > a,
.navbar-cr-default .navbar-cr-nav > .active > a:hover,
.navbar-cr-default .navbar-cr-nav > .active > a:focus {
    color: #555555;
    background-color: #e7e7e7;
}

.navbar-cr-default .navbar-cr-nav > .disabled > a,
.navbar-cr-default .navbar-cr-nav > .disabled > a:hover,
.navbar-cr-default .navbar-cr-nav > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
}

.navbar-cr-default .navbar-cr-toggle {
    border-color: #dddddd;
}

.navbar-cr-default .navbar-cr-toggle:hover,
.navbar-cr-default .navbar-cr-toggle:focus {
    background-color: #dddddd;
}

.navbar-cr-default .navbar-cr-toggle .icon-bar {
    background-color: #cccccc;
}

.navbar-cr-default .navbar-cr-collapse,
.navbar-cr-default .navbar-cr-form {
    border-color: #e7e7e7;
}

.navbar-cr-default .navbar-cr-nav > .open > a,
.navbar-cr-default .navbar-cr-nav > .open > a:hover,
.navbar-cr-default .navbar-cr-nav > .open > a:focus {
    color: #555555;
    background-color: #e7e7e7;
}

/*@media (max-width: 767px) {*/
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > li > a {
    color: #777777 !important;
}
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > li > a:hover,
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > li > a:focus {
    color: #333333 !important;
    background-color: transparent !important;
}
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .active > a,
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .active > a:hover,
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .active > a:focus {
    color: #555555 !important;
    background-color: #e7e7e7 !important;
}
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .disabled > a,
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .disabled > a:hover,
.navbar-cr-default-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .disabled > a:focus {
    color: #cccccc !important;
    background-color: transparent !important;
}
/*}*/

.navbar-cr-default .navbar-cr-link {
    color: #777777;
}

.navbar-cr-default .navbar-cr-link:hover {
    color: #333333;
}

.navbar-cr-inverse {
    background-color: #222222;
    border-color: #080808;
}

.navbar-cr-inverse .navbar-cr-brand {
    color: #999999;
}

.navbar-cr-inverse .navbar-cr-brand:hover,
.navbar-cr-inverse .navbar-cr-brand:focus {
    color: #ffffff;
    background-color: transparent;
}

.navbar-cr-inverse .navbar-cr-text {
    color: #999999;
}

.navbar-cr-inverse .navbar-cr-nav > li > a {
    color: #999999;
}

.navbar-cr-inverse .navbar-cr-nav > li > a:hover,
.navbar-cr-inverse .navbar-cr-nav > li > a:focus {
    color: #ffffff;
    background-color: transparent;
}

.navbar-cr-inverse .navbar-cr-nav > .active > a,
.navbar-cr-inverse .navbar-cr-nav > .active > a:hover,
.navbar-cr-inverse .navbar-cr-nav > .active > a:focus {
    color: #ffffff;
    background-color: #080808;
}

.navbar-cr-inverse .navbar-cr-nav > .disabled > a,
.navbar-cr-inverse .navbar-cr-nav > .disabled > a:hover,
.navbar-cr-inverse .navbar-cr-nav > .disabled > a:focus {
    color: #444444;
    background-color: transparent;
}

.navbar-cr-inverse .navbar-cr-toggle {
    border-color: #333333;
}

.navbar-cr-inverse .navbar-cr-toggle:hover,
.navbar-cr-inverse .navbar-cr-toggle:focus {
    background-color: #333333;
}

.navbar-cr-inverse .navbar-cr-toggle .icon-bar {
    background-color: #ffffff;
}

.navbar-cr-inverse .navbar-cr-collapse,
.navbar-cr-inverse .navbar-cr-form {
    border-color: #101010;
}

.navbar-cr-inverse .navbar-cr-nav > .open > a,
.navbar-cr-inverse .navbar-cr-nav > .open > a:hover,
.navbar-cr-inverse .navbar-cr-nav > .open > a:focus {
    color: #ffffff;
    background-color: #080808;
}

/*@media (max-width: 767px) {*/
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .dropdown-header {
    border-color: #080808 !important;
}
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed .divider-collapsed {
    background-color: #080808 !important;
}
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > li > a {
    color: #999999 !important;
}
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > li > a:hover,
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > li > a:focus {
    color: #ffffff !important;
    background-color: transparent !important;
}
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .active > a,
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .active > a:hover,
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .active > a:focus {
    color: #ffffff !important;
    background-color: #080808 !important;
}
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .disabled > a,
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .disabled > a:hover,
.navbar-cr-inverse-collapsed .navbar-cr-nav-collapsed .open-collapsed .dropdown-menu-collapsed > .disabled > a:focus {
    color: #444444 !important;
    background-color: transparent !important;
}
/*}*/

.navbar-cr-inverse .navbar-cr-link {
    color: #999999;
}

.navbar-cr-inverse .navbar-cr-link:hover {
    color: #ffffff;
}

