/**
 Context Menu
*/
.ptips-disabled-link {
	cursor: default !important;
	color: black;
	pointer-events: none;
}

/** Context menu appearance **/
.ptips-context-menu .caret {
	cursor: pointer;
	border-top: 7px solid;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
  	margin: 0 2px;
}

.ptips-context-menu.caret-wrapper:hover {
  border: 1px solid @btn-default-border;
  border-radius: 2px;
}

.ptips-context-menu .caret:hover {
	border-top-color: cornflowerblue;
}

.ptips-context-menu-content {
	box-sizing: border-box;
	padding: 0px;

  .btn-group-sm {
	width: 100%;
  }
}

.ptips-context-menu-content .btn-group-sm {
    box-sizing: border-box;

    > .btn {
	  font-size: 14px;
    }
}

.ptips-context-menu-content .btn {
	box-sizing: border-box;
	white-space: normal;
  	border: none;
  	width: 100%;

	/* Tudor likes this better */
	text-align: left;
}

.context-menu-wide{
	width: 175px;
}

.context-menu-wide .btn {
	width: 175px;
}

.context-group-title {
  padding-left: 10px;
  color: grey;
  font-size: 10px;
}

.combined-menu-content .context-group {
  position: relative;
  width: 100%;

  &:not(:first-child):before {
	content: "";
	position: absolute;
	left: 27px;
	top: 0;
	width: calc(100% - 27px);
	border-top: 1px solid @panel-default-border;
  }
}

/** Vehicle popover menu **/
.vehicle-click-popover .info-window-organisationId {
	text-align: left;
	font-size: 11px;
	margin-bottom: 10px;
}

.vehicle-click-popover .info-window-column {
	margin-right: 1em;
}

.vehicle-click-popover .info-window-column dl {
	margin-bottom: 0.7ex;
}

.vehicle-click-popover .btn-group-vertical .btn {
	text-align: left;
}

.vehicle-click-popover-content {
	display: flex;
	flex-direction: row;

	> div {
		padding: 0 15px;
		position: relative;

		&.context-group {
			min-width: 165px;
		}

		&:not(:first-child):before {
			.context-group-vertical-border;
		}
	}
}

.context-group-vertical-border() {
	content: "";
	position: absolute;
	top: 1em;
	left: 0;
	height: calc(100% - 1em);
	border-left: 1px solid @panel-default-border;
}

.pt-dropdown-menu {
	white-space: nowrap;
}