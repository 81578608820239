@import "core/series";
@import "core/dottedSeries";
@import "core/grid";
@import "core/axis";
@import "core/shape";
@import "core/toggle";

@import "addons/zoom";

.ptcharts-container {
  background-color: white;
  min-height:calc(100% - 25px);
}
