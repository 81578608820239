@import (reference) "../../vendor/bootstrap/less/bootstrap.less";

@text-search-row-padding-bottom: 10px;

.vehicle-list-view {
    white-space: nowrap;
    overflow-x: hidden;
    margin-right: -10px;

    .flex-container > .form-group {
        margin-bottom: 5px;
    }

    .single-select {
        .form-control();
        .input-size(@input-height-small; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @input-border-radius-small);
        margin-left: 0;
    }

    .control-toolbar .btn-sm {
        font-size: 16px;
        height: 16px;
        padding: 6px 9px;

        span.ptipsicon {
            position: relative;
            bottom: 3px;
        }
    }
}

.vehicle-list-filter {
    .form-group {
        margin-bottom: 5px;
        .btn {
            margin-left: 0;
        }
    }
}

.dropdown-checkbox {
    font-size: inherit;

    .checkbox {
        margin: 0 5px;
    }
}

.list-group-heading {
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
}

#vehicleSearchContainer {
    height: calc(100% - 44px);
    padding: 10px;

    .textSearchRow {
        padding-bottom: @text-search-row-padding-bottom;
    }
}

#vehicleSearchResultsContainer {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - @text-search-row-padding-bottom);
}

.veh-search-organisation-list {
    list-style-image: none;
    padding-left: 0px;
    white-space: nowrap;
}

.veh-search-depot-list {
    list-style-type: none;
    padding-left: 0px;
    white-space: nowrap;
}

.veh-search-vehicle-list {
    list-style-type: none;
    padding-left: 10px;
    white-space: nowrap;
}

.textSearchRow span {
    display: block;
    overflow: hidden;
}

.textSearchRow button {
    float: right;
    height: 20px;
    margin-left: 2px;
}

.search-view-organisation-item-label {
    cursor: pointer;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #ccdadf;
    background-color: #92a2a8;
    font-weight: normal;
}

.search-view-organisation-item-label-suffix {
    clear: both;
    height: 0px;
}

.search-view-depot-item-label {
    cursor: pointer;
    width: 100%;
    background-color: white;
    font-weight: normal;
}

.search-view-vehicle-item-label {
    cursor: pointer;
    width: 100%;
    background-color: white;
}
