/* == COLOR VARIABLES == */
@layout_tabcontainer-shadow: lighten(black, 30%);
@layout_tab-bg-INACTIVE-HOVER: lightgray;
@layout_tab-bg-ACTIVE: white;
@layout_tab-border-INACTIVE: transparent;
@layout_tab-border-ACTIVE: #ddd;
@layout_tab-border-ALL-DRAGENTER: red;
@layout_tab-text-INACTIVE: darkgray;
@layout_tab-text-INACTIVE-HOVER: white;
@layout_tab-text-ACTIVE: darken(darkgray, 25%);
@layout_tab-shadow-ACTIVE: gray;
@layout_tabclose-bg-HOVER: #f66;


#appFrame,
.layout-primary,
.layout-external,
.layout-dashboard {
    height: 100%;
}

#navigation {
    margin: 0;
}

.vertical-splitter-container {
    height: calc(100% - 83px);
}

@media print {
    // the status bar disappears, so we can make this a little bigger for print purposes
    .vertical-splitter-container {
        height: calc(100% - 55px);
    }
}

.horizontal-splitter-container {
    height: 100%;
}

.vertical-splitter-container,
.horizontal-splitter-container {
    &.k-widget {
        border: none;
    }
}

.layout-position {
    height: 100%;
    overflow: hidden;
    .control-container {
        padding: 10px 10px 0 10px;
    }
    .add-control-container {
        display: inline-block;
        padding: 15px 15px 15px 0;
    }
    .pt-scroll-content {
        height: 34px;
        box-shadow: inset 0 -8px 20px -15px @layout_tabcontainer-shadow;
    }
    ul.pt-scrollable-element {
        white-space: nowrap;
        border-bottom: none;
        li {
            float: none;
            display: inline-block;
        }
    }
    .control-set {
        .nav > li > a {
            min-width: 12px;
            padding: 6px 10px;
            line-height: 1.666666666;
        }
        > .tab-content {
            height: calc(100% - 35px);
            overflow: auto;
        }
    }
}

.control-tab-btn {
    cursor: pointer;
    margin-left: 5px;
}

.pt-control-selector {
    display: flex;
    flex-wrap: wrap;

    .context-group {
        padding-left: 15px;
        width: 200px;
        margin-bottom: 15px;
        position: relative;

        &:not(:first-child):before {
            .context-group-vertical-border;
        }
    }

    .btn.selected {
        z-index: 3;
        border: 1px solid darken(@btn-default-border, 12%);;
        background-color: #e6e6e6;
        font-weight: bold;
    }
}

.dashboard-toolbar {
    .toolbar-header {
        margin: 10px 0;
    }
}

.control-toolbar {
    margin-top: 10px;
    margin-bottom: 10px;
}


/* == LAYOUTPOSITION WRAPPER == */
.pt-panel {
    width: 100%;
    height: 100%;
}


/* == LAYOUTPOSITION HEADER == */
.pt-panel-header {

    /* -- Container -- */
    // Common
    margin-bottom: 0;
    padding: 5px 0 0;
    list-style-type: none;
    line-height: 14px;

    // States
    &.tab-dragenter {
        border: 2px dotted @layout_tab-border-ALL-DRAGENTER;
        border-bottom: none;
    }

    /* -- Tab -- */
    // Both 'Control' Tab and 'Add' Tab
    .pt-panel-header-tab, .tab-add {
        // Common
        float: left;
        box-sizing: border-box;
        margin: 0 0 -1px 5px;
        padding: 8px 20px 6px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        min-height: 30px;

        // States
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        color: darkgray;
        &.tab-active {
            border-top: 1px solid @layout_tab-border-ACTIVE;
            border-left: 1px solid @layout_tab-border-ACTIVE;
            border-right: 1px solid @layout_tab-border-ACTIVE;
            background: white;
            box-shadow: 0 1px 15px -5px @layout_tab-shadow-ACTIVE;
            color: @layout_tab-text-ACTIVE;
        }
        &:not(.tab-active):hover {
            background: @layout_tab-bg-INACTIVE-HOVER;
            color: @layout_tab-text-INACTIVE-HOVER;
        }
    }

    // 'Control' Tab only
    .pt-panel-header-tab {
        // States
        &:hover { cursor: move; }
        &.tab-dragenter {
            border: 2px dotted @layout_tab-border-ALL-DRAGENTER;
            border-bottom: none;
        }
    }

    // 'Add' Tab only
    .tab-add {
        // States
        &:hover { cursor: pointer; }
    }

    /* -- Tab Close button -- */
    .pt-panel-header-tab > .tab-close {
        // Common
        display: inline-block;
        float: right;
        margin: -1px 0 0 10px;
        padding: 1px;
        border-radius: 15px;
        width: 15px; height: 15px;
        transform: rotateZ(45deg);
        font: 20px/13px monospace, sans-serif;
        text-align: center;

        // States
        &:hover {
            color: @layout_tab-text-INACTIVE-HOVER;
            background: @layout_tabclose-bg-HOVER;
            cursor: pointer;
        }
    }
}


/* == LAYOUTPOSITION CONTENT == */
.pt-panel-content {
    // Container
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: calc(100% - 35px);

    // Content
    section {
        height: 100%;
    }
}
