/** ON/OFF with div and ng-src img rather than background image **/
input.on-off-img {
  opacity: 0;
  width: 0;
  height: 0;
}

label.on-off-img {
  cursor: pointer;
}

label.on-off-img[disabled] {
  cursor: default;
  opacity: 0.65;
}

label.on-off-img > img {
  width: 3.5em;
}

/**
 * ON/OFF-style checkboxes and radio buttons.
 *
 * N.B. Apply the .on-off class to BOTH the <input> AND its <label>.
 * N.B. The <input> MUST be placed BEFORE its <label> in the HTML, even though the on/off switch will appear after it.
 */
input.on-off ~ label.on-off {
  margin: 0;
  clear: none;
  cursor: pointer;
  width: 100%;
  /* Use em so the image scales with the text */
  border-right: 4em;
  background-size: 3.5em auto;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url('@{img-path}/off_126x46.png');
}

input.on-off:checked ~ label.on-off {
  background-image: url('@{img-path}/on_126x46.png');
}

/* Make the <input> itself invisible but functional. */
input.on-off {
  opacity: 0;
  width: 0;
  height: 0;
}

label.on-off > i {
  padding: 5px;
}