.search-box, .clearable-text-wrapper {
  position: relative;
}

.powered-by-google {
  background: url('@{img-path}/powered-by-google.png') right no-repeat;
}

.clear-text-icon {
  position: absolute;
  right: 0;
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.clear-text-icon::after {
  content: "+";
  transform: rotateZ(45deg);
  font-size: x-large;
  background-size: 25px 25px;
  display: inline;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -40px;
  text-align: center;
  cursor: pointer;
}

.progress {
  margin: 10px;
  margin-top: 0px;
  height: 15px;
}

.search-results .ptipsicon-pin-location {
    margin-right: 5px;
}

.pt-search-result-row a.menu-link{
    display: none;
}

.search-selection-options {
  padding: 5px;
  padding-bottom: 10px;
}

.search-selection-options .show-label {
  padding-left: 10px;
}

.search-selection-options .search-type-input-select {
  padding-left: 10px;
}

.search-selection-options .search-type-input-select > label input {
  margin-left: 5px;
  vertical-align: text-bottom;
}

.search-highlight{
  background: yellow;
}