.mta {
    overflow: hidden;
    margin-bottom: 20px;

    .loading-indicator {
        content: url('@{img-path}/animated-loading-16x16.gif');
        display: flex;
        align-items: center;
        padding: 3px;

        div {
            padding: 3px;
        }
    }

    .vehicle-list, .trip-list {
        height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        li {
            padding: 5px 2px;

            .icon-container {
                vertical-align: top;
            }

            .content-container {
                line-height: 18px;
                padding-left: 5px;
            }

            .content-container, .icon-container {
                display: inline-block;
            }
        }

        li:nth-child(odd) {
            background-color: @lighter-grey;
        }

        li.selected {
            background-color: @system-blue;

            color: white;
            a {
                color: white;
            }
        }
    }

    .trip-list {
        li {
            width: 100%;
            line-height: 18px;
            padding: 5px 2px;
        }
    }

    .mta-view-container {
        padding: 5px;
        overflow:hidden;
    }

    .assignment-box {
        display: flex;
        align-items: center;
        justify-content: center;

        float: left;
        border: 1px solid black;
        border-radius: 8px;
        padding: 10px;
        background-color: rgb(234, 239, 245);
        border-color: rgb(141, 177, 226);
        height: 36px;

        &.medium {
            width: calc(50% - 150px);
        }

        &.large {
            width: calc(50% - 100px);
        }

        &.error {
            background-color: rgb(242, 220, 218) !important;
            color: rgb(146, 57, 49);
            border-color: rgb(146, 57, 49);
        }

        &.unselected {
            color: rgb(90, 130, 180);
        }
    }

}

#mtaTreeViewTable {
    height: calc(100% - 98px);
}

#mta-assignment-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
        display: block;
        float: left;
        margin-left: 0 !important;
        line-height: 19px;
        height: 24px;

        .ptipsicon {
            font-size: 16px;
            position: relative;
            top: 2px;
        }
    }
}

.mta-assignment-line {
    width: 50px;
    border-color: rgb(141, 177, 226);
    display: block;
    position: relative;
    bottom: -2.4em;
    height: 30px;
    float: left;
    margin: 0;
}

.mta-assignment-button-container {
  margin-top: 12px;
}

.modal-error {
    background-color: #c05046;
    color: white;
    border-radius: 5px 5px 0 0;
}

.vehicle-list, .trip-list {
    border: 1px solid #ccc;
    cursor: default;
    margin-top: 5px;
}

pt-mta-trip-filter-panel, pt-mta-vehicle-filter-panel {
    .mtaFilterTitle {
        position: relative;
        h3 {
            margin: 0;
            position: absolute;
            bottom: 0;
            font-size: 18px;
        }
    }

    .filter-panel-standard-filters-status {
        padding: 10px;
    }

    > section {
        display: flex;
        flex-direction: row;
    }
}

pt-mta-trip-list, pt-mta-vehicle-list {

    header {
        min-width: 80px;
    }

    > div {
        min-width: 70px;
    }
}

pt-mta-search-filter {
    margin-top: 5px;
    display: block;
}

.mta-depot-selection {
    width: 100%;
    text-align: right;
}

pt-lozenges {
    flex: 1;
    padding: 7px;

    .lozenge-container {
        display: inline-block;
        float: right;
        margin: 0 5px;
    }

    .popover-container {
        padding-left: 0;
    }
}
